<template>
    <b-container class="mt-4 purchase_part px-0">
        <b-card :header-class="`purchase_part_header purchase_part-header_number ${cardCssClass} pr-2 py-2 position-relative`" body-class="purchase_part-body p-0" border-variant="light">
            <template #header>
                <b-row class="w-100">
                    <b-col class="text-nowrap purchase-header-text">
                        <b-badge class="my-auto fs-14 mr-2" variant="primary">223-ФЗ</b-badge>
                        <router-link class="white-system_link-style" :to="{ name: 'Fl223PurchaseShow', params: { id: contract.id } }" exact target="_blank">{{ contract.reg_number }}</router-link>
                        <template v-if="contract.purchase_object"> На закупку "{{ contract.purchase_object }}"</template>
                    </b-col>
                    <b-col class="text-nowrap text-uppercase" md="auto">
                        {{ contract.status.title }}
                    </b-col>
                </b-row>
                <b-icon :icon="cardIcon" class="card-icon" />
            </template>
            <div class="purchase_part-subheader">
                <b-col xs="12" sm="6" lg="3" class="d-flex align-items-center">
                    <span class="fs-12 green-color">
                        Цена закупки: <span class="fw-600">{{ $formatPrice(contract.start_price) }} руб.</span>
                    </span>
                </b-col>
                <b-col xs="12" sm="6" lg="3" class="d-flex align-items-center">
                    <span class="fs-12 green-color">
                        Предложений: <span class="fw-600">{{ contract.proposals_count }}</span>
                    </span>
                </b-col>
                <b-col xs="12" sm="6" lg="3" class="d-flex align-items-center">
                    <span class="fs-12 green-color"
                        >Лучшее: <span class="fw-600"> {{ $formatPrice(contract.best_proposal_price) + ' руб.' }}</span>
                    </span>
                </b-col>
                <b-col xs="12" sm="6" lg="3" class="d-flex align-items-center">
                    <span class="fs-12 green-color">
                        Снижение: <span class="fw-600">{{ contract.discount_percentage }} %</span>
                    </span>
                </b-col>
            </div>
            <b-row class="purchase_part-body py-1">
                <b-col md="4" class="purchase_part-body_col">
                    <div>
                        <div class="my-1" v-if="!!contract.planned_contract_execution_datetime">
                            <p class="fs-12 m-0 grey-color">Планируемая дата исполнения:</p>
                            <p class="fw-600 fs-12 m-0 grey-color">
                                {{ getDateFormat(contract.planned_contract_execution_datetime) }}
                            </p>
                        </div>
                        <div class="my-1" v-if="contract.status.id === 'contract:active' || contract.status.id === 'contract:fulfilled' || contract.status.id === 'contract:unfulfilled' || contract.status.id === 'contract:terminated'">
                            <p class="fs-12 m-0 grey-color">Договор заключен:</p>
                            <p class="fs-12 m-0 grey-color fw-600 time_to_end">
                                {{ getDateFormat(contract.contract_date) }}
                            </p>
                        </div>
                    </div>
                    <div class="my-1">
                        <p class="fs-12 m-0 grey-color" v-if="contract.contract_price">
                            Цена договора:
                            <span class="fw-600">{{ $formatPrice(contract.contract_price) }} руб.</span>
                        </p>
                    </div>
                    <div v-if="isCreator" class="my-1">
                        <fl223-purchase-comment-edit-block :purchase="contract" @refresh="refresh" />
                    </div>
                </b-col>
                <b-col md="4" class="purchase_part-body_col">
                    <div>
                        <div class="my-1">
                            <p class="fs-12 grey-color m-0">Предмет закупки:</p>
                            <p class="fs-12 grey-color fw-600">
                                {{ contract.deliverable_group_name }}
                            </p>
                        </div>
                        <div class="my-1 d-flex flex-column">
                            <p class="fs-12 grey-color m-0">Заказчик:</p>
                            <router-link v-if="!contract.is_customer" class="system_link-style fw-600 text-uppercase fs-12" :to="'/organizations/' + contract.customer_id" target="_blank">
                                {{ contract.customer_name }}
                            </router-link>
                            <div v-else class="fw-600 fs-12">Моя организация</div>
                        </div>
                        <div class="my-1 d-flex flex-column">
                            <p class="fs-12 grey-color m-0">Поставщик:</p>
                            <router-link v-if="contract.is_customer" class="system_link-style fw-600 text-uppercase fs-12" :to="'/organizations/' + contract.supplier_id" target="_blank">
                                {{ contract.supplier_name }}
                            </router-link>
                            <div v-else class="fw-600 fs-12">Моя организация</div>
                        </div>
                        <div class="my-1">
                            <p class="fs-12 m-0 grey-color">
                                Количество позиций:
                                <span class="fw-600">{{ contract.deliverables_count }}</span>
                            </p>
                        </div>
                        <div v-if="contract.delivery_addresses && contract.delivery_addresses.length" class="my-1">
                            <p class="fs-12 m-0 grey-color">Место доставки или указание на самовывоз:</p>
                            <p class="fs-12 m-0 grey-color fw-600" v-for="(address, index) in contract.delivery_addresses" :key="index">
                                {{ address }}
                            </p>
                        </div>
                    </div>
                </b-col>
                <b-col md="4" class="purchase_part-body_col atmo-column atmo-special-style">
                    <div>
                        <p class="d-flex align-items-center green-color fs-12 fw-600 my-1">
                            <i class="atmo-icon-search link-icon"></i>
                            <router-link class="system_link-style" :to="{ name: 'Fl223PurchaseShow', params: { id: contract.id } }" exact target="_blank">Информация о закупке</router-link>
                        </p>
                        <p class="d-flex align-items-center green-color fs-12 fw-600 my-1">
                            <i class="atmo-icon-shield link-icon"></i>
                            <router-link class="system_link-style" :to="{ name: 'Fl223PurchaseShow', params: { id: contract.id }, hash: '#atmo-protocol' }" exact target="_blank">Итоговый протокол</router-link>
                        </p>
                        <p class="d-flex align-items-center green-color fs-12 fw-600 my-1">
                            <i class="atmo-icon-contract link-icon"></i>
                            <router-link class="system_link-style" :to="{ name: 'Fl223PurchaseShow', params: { id: contract.id }, hash: '#atmo-contract' }" exact target="_blank">Договор</router-link>
                        </p>
                        <div v-if="isCreator" class="my-1">
                            <b-btn class="btn-card-action btn-card-action-green my-1" @click="copyPurchase">Копировать</b-btn>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-card>
    </b-container>
</template>

<script>
import Fl223PurchaseCommentEditBlock from '@/components/cabinets/fl223/partials/fl223-purchase-comment-edit-block.vue';

export default {
    name: 'cabinet-fl223-contract-card',
    components: { Fl223PurchaseCommentEditBlock },
    props: {
        contract: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            ratingModalVisible: false,
            ratingValue: 5,
            ratingSending: false
        };
    },
    methods: {
        refresh() {
            this.$emit('refresh');
        },
        async copyPurchase() {
            const response = await this.$api.products.fl223.purchases.copy(this.contract.id);
            if (response && response.id) {
                await this.$router.push({ name: 'Fl223PurchaseEdit', params: { id: response.id } });
            }
        }
    },
    computed: {
        cardCssClass() {
            switch (this.contract.status.id) {
                case 'contract:unfulfilled':
                case 'contract:terminated':
                case 'contract:failed':
                    return 'card-header-yellow';
                default:
                    return 'card-header-green';
            }
        },
        cardIcon() {
            switch (this.contract.status.id) {
                case 'contract:active':
                    return 'file-earmark-ruled';
                case 'contract:fulfilled':
                    return 'file-earmark-check';
                case 'contract:unfulfilled':
                case 'contract:failed':
                case 'contract:terminated':
                    return 'x-circle';
                default:
                    return '';
            }
        },
        isCreator() {
            return this.$store.state.user.organization_id === this.contract.customer_id;
        }
    }
};
</script>

<style scoped>
.link-icon {
    padding-right: 5px;
    color: #d5d5d5;
    font-size: medium;
}
.card-icon {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
}
.purchase_part {
    background: #fff;
}
.card-header-grey {
    background-color: #959ba4 !important;
}
.card-header-green {
    background-color: var(--green-color);
}
.card-header-blue {
    background-color: #40acb4 !important;
}
.card-header-yellow {
    background-color: #f6b33a !important;
}
.purchase-header-text {
    text-overflow: ellipsis;
    overflow: hidden;
}
.purchase_part-header {
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
    background-color: var(--green-color);
    padding: 9px 8px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.purchase_part-subheader {
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
    background-color: #e4e6e8;
    padding: 4px 8px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.purchase_part-body {
    padding: 14px 26px;
}

.purchase_part-header_number {
    font-size: 14px;
    color: #fff;
    margin: 0;
    font-weight: 600;
}

.purchase_part-header_label {
    text-transform: uppercase;
    color: #fff;
    margin: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 12px;
}

.purchase_part-body_col + .purchase_part-body_col > div::before {
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    background-color: #d9d9de;
    left: 0px;
    top: 0px;
}
@media screen and (max-width: 767px) {
    .purchase_part-body {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .purchase_part-body_col > div::before {
        display: none;
    }

    .hidden-mobile {
        display: none;
    }

    .time_to_end {
        font-size: 12px !important;
        margin-bottom: 0 !important;
        margin-left: 5px !important;
    }

    .purchase_status {
        margin-left: 5px !important;
    }
}
</style>
