var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "mt-4 purchase_part px-0" },
    [
      _c(
        "b-card",
        {
          attrs: {
            "header-class": `atmo-override-color atmo-entity-item-title ${_vm.cardCssClass} pr-2 position-relative`,
            "body-class": "purchase_part-body p-0",
            "border-variant": "light",
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c(
                    "b-row",
                    { staticClass: "w-100" },
                    [
                      _c(
                        "b-col",
                        { staticClass: "text-nowrap purchase-header-text" },
                        [
                          _c(
                            "b-badge",
                            {
                              staticClass: "my-auto fs-14 mr-2",
                              attrs: { variant: "primary" },
                            },
                            [_vm._v(_vm._s(_vm.notice.product.title))]
                          ),
                          _vm.notice.only_smp
                            ? _c(
                                "b-badge",
                                {
                                  staticClass: "my-auto fs-14 mr-2",
                                  attrs: { variant: "custom-orange" },
                                },
                                [_vm._v("Только СМП")]
                              )
                            : _vm._e(),
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "CommercialPurchaseShow",
                                  params: { id: _vm.notice.id },
                                },
                                exact: "",
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                "№ " + _vm._s(_vm.notice.registration_number)
                              ),
                            ]
                          ),
                          _vm.notice.purchase_object
                            ? [
                                _vm._v(
                                  ' На закупку "' +
                                    _vm._s(_vm.notice.purchase_object) +
                                    '"'
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "text-nowrap text-uppercase status-title",
                          attrs: { md: "auto" },
                        },
                        [_vm._v(" " + _vm._s(_vm.notice.status.title) + " ")]
                      ),
                    ],
                    1
                  ),
                  _c("b-icon", {
                    staticClass: "card-icon",
                    attrs: { icon: _vm.cardIcon },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            { staticClass: "purchase_part-subheader" },
            [
              _c(
                "b-col",
                {
                  staticClass: "d-flex align-items-center",
                  attrs: { lg: "3", sm: "6", xs: "12" },
                },
                [
                  _c("span", { staticClass: "fs-14 green-color" }, [
                    _vm._v(" Сумма закупки: "),
                    _c("span", { staticClass: "fw-600" }, [
                      _vm._v(
                        _vm._s(_vm.$formatPrice(_vm.notice.starting_price)) +
                          " руб."
                      ),
                    ]),
                  ]),
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass: "d-flex align-items-center",
                  attrs: { lg: "3", sm: "6", xs: "12" },
                },
                [
                  _c("span", { staticClass: "fs-14 green-color" }, [
                    _vm._v(" Предложений: "),
                    !_vm.notice.are_proposals_hidden
                      ? _c("span", {
                          staticClass: "fw-600",
                          domProps: {
                            textContent: _vm._s(_vm.notice.proposals_count),
                          },
                        })
                      : _c("span", { staticClass: "fw-600" }, [
                          _vm._v("Скрыто"),
                        ]),
                  ]),
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass: "d-flex align-items-center",
                  attrs: { lg: "3", sm: "6", xs: "12" },
                },
                [
                  _c(
                    "span",
                    { staticClass: "fs-14 green-color" },
                    [
                      _vm._v(" Лучшее: "),
                      !_vm.notice.are_proposals_hidden
                        ? [
                            _vm.notice.best_proposal_price > 0
                              ? _c("span", { staticClass: "fw-600" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$formatPrice(
                                        _vm.notice.best_proposal_price
                                      )
                                    ) + " руб."
                                  ),
                                ])
                              : _c("span", { staticClass: "fw-600" }, [
                                  _vm._v("-"),
                                ]),
                          ]
                        : _c("span", { staticClass: "fw-600" }, [
                            _vm._v("Скрыто"),
                          ]),
                    ],
                    2
                  ),
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass: "d-flex align-items-center",
                  attrs: { lg: "3", sm: "6", xs: "12" },
                },
                [
                  _c("span", { staticClass: "fs-14 green-color" }, [
                    _vm._v(" Снижение: "),
                    !_vm.notice.are_proposals_hidden
                      ? _c("span", { staticClass: "fw-600" }, [
                          _vm._v(_vm._s(_vm.notice.discount_percentage) + "%"),
                        ])
                      : _c("span", { staticClass: "fw-600" }, [
                          _vm._v("Скрыто"),
                        ]),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "purchase_part-body py-2" },
            [
              _c(
                "b-col",
                { staticClass: "purchase_part-body_col", attrs: { md: "3" } },
                [
                  _c(
                    "div",
                    [
                      _vm.notice.status.id === "accepting"
                        ? [
                            _c(
                              "p",
                              { staticClass: "fs-14 m-0 grey-color my-1" },
                              [
                                _vm._v(" До окончания приёма предложений: "),
                                _c("timer-new", {
                                  attrs: {
                                    data: _vm.notice.proposal_accept_end_date,
                                  },
                                  on: {
                                    timeout: function ($event) {
                                      _vm.isProposalTimedOut = true
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            !_vm.isProposalTimedOut &&
                            _vm.$store.getters.isLoggedIn
                              ? _c("commercial-new-proposal-action-button", {
                                  attrs: { noticeitem: _vm.notice },
                                })
                              : _vm._e(),
                          ]
                        : _vm._e(),
                      _vm.notice.status.id === "summarizing"
                        ? _c(
                            "p",
                            { staticClass: "fs-14 m-0 grey-color my-1" },
                            [_vm._v("Прием предложений завершен")]
                          )
                        : _vm._e(),
                      _vm.notice.status.id === "summarized"
                        ? _c(
                            "p",
                            { staticClass: "fs-14 m-0 grey-color my-1" },
                            [
                              _vm._v(" Подведены итоги: "),
                              _c("span", { staticClass: "fw-600" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.getDateTimeFormat(
                                      _vm.notice.protocol_date
                                    )
                                  )
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.notice.status.id === "failed"
                        ? _c(
                            "p",
                            { staticClass: "fs-14 m-0 grey-color my-1" },
                            [
                              _vm._v(" Подведены итоги: "),
                              _c("span", { staticClass: "fw-600" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.getDateTimeFormat(
                                      _vm.notice.proposal_accept_end_date
                                    )
                                  )
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.notice.status.id === "contract:active" ||
                      _vm.notice.status.id === "contract:fulfilled" ||
                      _vm.notice.status.id === "contract:unfulfilled" ||
                      _vm.notice.status.id === "contract:terminated"
                        ? _c(
                            "p",
                            { staticClass: "fs-14 m-0 grey-color my-1" },
                            [
                              _vm._v(" Договор заключен: "),
                              _c("span", { staticClass: "fw-600" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.getDateFormat(
                                      _vm.notice.contract
                                        .contract_conclusion_date
                                    )
                                  )
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      !!_vm.notice.planned_date_contract_fulfilled
                        ? _c(
                            "p",
                            { staticClass: "fs-14 m-0 grey-color my-1" },
                            [
                              _vm._v(" Планируемая дата исполнения: "),
                              _c("span", { staticClass: "fw-600" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.getDateFormat(
                                      _vm.notice.planned_date_contract_fulfilled
                                    )
                                  )
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _c("p", { staticClass: "fs-14 m-0 grey-color my-1" }, [
                        _vm._v(" Количество позиций: "),
                        _c("span", { staticClass: "fw-600" }, [
                          _vm._v(_vm._s(_vm.notice.deliverables_count)),
                        ]),
                      ]),
                    ],
                    2
                  ),
                ]
              ),
              _c(
                "b-col",
                { staticClass: "purchase_part-body_col", attrs: { md: "6" } },
                [
                  _c(
                    "div",
                    [
                      _c("p", { staticClass: "fs-14 m-0 grey-color my-1" }, [
                        _vm._v(" Предмет закупки: "),
                        _c("span", { staticClass: "fw-600" }, [
                          _vm._v(_vm._s(_vm.notice.deliverable_group_title)),
                        ]),
                      ]),
                      _c("p", { staticClass: "fs-14 m-0 grey-color my-1" }, [
                        _vm._v(" Заказчик: "),
                        _c(
                          "span",
                          { staticClass: "fw-600" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass:
                                  "system_link-style text-uppercase fs-14",
                                attrs: {
                                  to:
                                    "/organizations/" + _vm.notice.customer.id,
                                  target: "_blank",
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.notice.customer.name) + " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm.notice.delivery_addresses &&
                      _vm.notice.delivery_addresses.length > 0
                        ? [
                            _c(
                              "p",
                              { staticClass: "fs-14 m-0 grey-color my-1" },
                              [_vm._v("Адреса доставки или самовывоза:")]
                            ),
                            _c(
                              "ul",
                              {
                                staticStyle: {
                                  "padding-left": "20px",
                                  "list-style-type": "disc !important",
                                },
                              },
                              _vm._l(
                                _vm.notice.delivery_addresses,
                                function (address, index) {
                                  return _c(
                                    "li",
                                    {
                                      key: index,
                                      staticClass:
                                        "fs-14 m-0 grey-color fw-600",
                                    },
                                    [_vm._v(" " + _vm._s(address) + " ")]
                                  )
                                }
                              ),
                              0
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass:
                    "purchase_part-body_col atmo-column atmo-special-style",
                  attrs: { md: "3" },
                },
                [
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "d-flex align-items-center green-color fs-14 fw-600 my-1",
                      },
                      [
                        _c("i", { staticClass: "atmo-icon-search link-icon" }),
                        _c(
                          "router-link",
                          {
                            staticClass: "system_link-style",
                            attrs: {
                              to: {
                                name: "CommercialPurchaseShow",
                                params: { id: _vm.notice.id },
                              },
                              exact: "",
                              target: "_blank",
                            },
                          },
                          [_vm._v("Информация о закупке")]
                        ),
                      ],
                      1
                    ),
                    _vm.notice.status.id === "contract:active"
                      ? _c(
                          "p",
                          {
                            staticClass:
                              "d-flex align-items-center green-color fs-14 fw-600 my-1",
                          },
                          [
                            _c("i", {
                              staticClass: "atmo-icon-contract link-icon",
                            }),
                            _c(
                              "router-link",
                              {
                                staticClass: "system_link-style",
                                attrs: {
                                  to: {
                                    name: "CommercialPurchaseShow",
                                    params: { id: _vm.notice.id },
                                    hash: "#atmo-contract",
                                  },
                                  exact: "",
                                  target: "_blank",
                                },
                              },
                              [_vm._v("Договор")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }