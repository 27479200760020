<template>
    <div class="table-responsive">
        <table class="table b-table">
            <fragment v-for="(dispute, index) in this.$props.disputes" v-bind:key="index">
                <tr>
                    <th width="1">№</th>
                    <th>Протокол разногласий (поставщик)</th>
                    <th>Комментарий поставщика</th>
                    <th width="100">Направлен</th>
                    <th width="50">Подпись</th>
                </tr>
                <tr>
                    <td :rowspan="dispute.reply.isEmpty() ? 1 : 3">{{ index + 1 }}</td>
                    <td>
                        <a class="atmo-button-link" style="color: var(--green-color) !important" :href="$getAttachmentDownloadLink(dispute.protocol.attachment.file_id)" target="_blank">{{ dispute.protocol.attachment.name }}</a>
                    </td>
                    <td>{{ dispute.protocol.comment }}</td>
                    <td class="text-wrap">{{ dispute.protocol.created_at | formatdate('withSeconds') }}</td>
                    <td class="text-center text-nowrap">
                        <action-button-small icon="clipboard-check" title="Информация о подписи" @click="$props.pivc.invokeSignaturePopup([dispute.protocol.signature])" />
                        <action-button-small v-if="dispute.protocol.signature.poa_number && dispute.protocol.signature.poa_check_link" class="ml-1" icon="clipboard-plus" title="Информация о МЧД" variant="warning" @click="$props.pivc.invokeSignaturePopup([dispute.protocol.signature])" />
                    </td>
                </tr>
                <template v-if="!dispute.reply.isEmpty()">
                    <tr>
                        <th>Ответ на протокол разногласий (заказчик)</th>
                        <th>Комментарий заказчика</th>
                        <th>Направлен</th>
                        <th>Подпись</th>
                    </tr>
                    <tr>
                        <td>
                            <a class="atmo-button-link" style="color: var(--green-color) !important" :href="$getAttachmentDownloadLink(dispute.reply.attachment.file_id)" target="_blank">{{ dispute.reply.attachment.name }}</a>
                        </td>
                        <td>{{ dispute.reply.comment }}</td>
                        <td>{{ dispute.reply.created_at | formatdate('withSeconds') }}</td>
                        <td class="text-center text-nowrap">
                            <action-button-small icon="clipboard-check" title="Информация о подписи" @click="$props.pivc.invokeSignaturePopup([dispute.reply.signature])" />
                            <action-button-small v-if="dispute.reply.signature.poa_number && dispute.reply.signature.poa_check_link" class="ml-1" icon="clipboard-plus" title="Информация о МЧД" variant="warning" @click="$props.pivc.invokeSignaturePopup([dispute.reply.signature])" />
                        </td>
                    </tr>
                </template>
            </fragment>
        </table>
    </div>
</template>

<script>
export default {
    props: {
        pivc: { required: true, type: Object },
        disputes: { required: true, type: Array }
    }
};
</script>
