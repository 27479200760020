var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "h5",
        { staticClass: "grey-color mt-3 mb-1 main_text font-weight-bold" },
        [_vm._v("Разногласия по договору")]
      ),
      _c("div", { staticClass: "table-responsive" }, [
        _c(
          "table",
          { staticClass: "table b-table" },
          _vm._l(_vm.disagreements, function (disagreement, index) {
            return _c(
              "fragment",
              { key: index },
              [
                _c("tr", [
                  _c("th", { attrs: { width: "1" } }, [_vm._v("№")]),
                  _c("th", { staticClass: "text-nowrap" }, [
                    _vm._v("Протокол разногласий (поставщик)"),
                  ]),
                  _c("th", [_vm._v("Комментарий поставщика")]),
                  _c("th", { attrs: { width: "100" } }, [_vm._v("Направлен")]),
                  _c("th", { attrs: { width: "50" } }, [_vm._v("Подпись")]),
                ]),
                _c("tr", [
                  _c(
                    "td",
                    {
                      attrs: {
                        rowspan: !disagreement.customer_stored_document ? 1 : 3,
                      },
                    },
                    [_vm._v(_vm._s(index + 1))]
                  ),
                  _c("td", { staticClass: "text-nowrap" }, [
                    _c(
                      "a",
                      {
                        staticClass: "atmo-button-link",
                        staticStyle: { color: "var(--green-color) !important" },
                        attrs: {
                          href: _vm.$getAttachmentDownloadLink(
                            disagreement.supplier_stored_document.id
                          ),
                          target: "_blank",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(disagreement.supplier_stored_document.name)
                        ),
                      ]
                    ),
                  ]),
                  _c("td", [_vm._v(_vm._s(disagreement.supplier_comment))]),
                  _c("td", { staticClass: "text-nowrap" }, [
                    _vm._v(
                      _vm._s(_vm.$formatDateTime(disagreement.created_at))
                    ),
                  ]),
                  _c(
                    "td",
                    { staticClass: "text-center text-nowrap" },
                    [
                      _c("action-button-small", {
                        attrs: {
                          icon: "clipboard-check",
                          title: "Информация о подписи",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.showSign(
                              true,
                              disagreement.supplier_signature
                            )
                          },
                        },
                      }),
                      disagreement.supplier_signature.poa_number &&
                      disagreement.supplier_signature.poa_check_link
                        ? _c("action-button-small", {
                            staticClass: "ml-1",
                            attrs: {
                              icon: "clipboard-plus",
                              title: "Информация о МЧД",
                              variant: "warning",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.showSign(
                                  true,
                                  disagreement.supplier_signature
                                )
                              },
                            },
                          })
                        : _vm._e(),
                      _c("action-button-small", {
                        staticClass: "ml-1",
                        attrs: {
                          icon: "cloud-download",
                          title: "Скачать файл подписи",
                          variant: "outline",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.download(
                              disagreement.supplier_signature.md5,
                              false,
                              disagreement.supplier_stored_document.name
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                disagreement.customer_stored_document
                  ? [
                      _c("tr", [
                        _c("th", { staticClass: "text-nowrap" }, [
                          _vm._v("Ответ на протокол разногласий (заказчик)"),
                        ]),
                        _c("th", [_vm._v("Комментарий заказчика")]),
                        _c("th", [_vm._v("Направлен")]),
                        _c("th", [_vm._v("Подпись")]),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "text-nowrap" }, [
                          _c(
                            "a",
                            {
                              staticClass: "atmo-button-link",
                              staticStyle: {
                                color: "var(--green-color) !important",
                              },
                              attrs: {
                                href: _vm.$getAttachmentDownloadLink(
                                  disagreement.customer_stored_document.id
                                ),
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  disagreement.customer_stored_document.name
                                )
                              ),
                            ]
                          ),
                        ]),
                        _c("td", [
                          _vm._v(_vm._s(disagreement.customer_comment)),
                        ]),
                        _c("td", { staticClass: "text-nowrap" }, [
                          _vm._v(
                            _vm._s(_vm.$formatDateTime(disagreement.updated_at))
                          ),
                        ]),
                        _c(
                          "td",
                          { staticClass: "text-center text-nowrap" },
                          [
                            _c("action-button-small", {
                              attrs: {
                                icon: "clipboard-check",
                                title: "Информация о подписи",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.showSign(
                                    true,
                                    disagreement.customer_signature
                                  )
                                },
                              },
                            }),
                            disagreement.customer_signature.poa_number &&
                            disagreement.customer_signature.poa_check_link
                              ? _c("action-button-small", {
                                  staticClass: "ml-1",
                                  attrs: {
                                    icon: "clipboard-plus",
                                    title: "Информация о МЧД",
                                    variant: "warning",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showSign(
                                        true,
                                        disagreement.customer_signature
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                            _c("action-button-small", {
                              staticClass: "ml-1",
                              attrs: {
                                icon: "cloud-download",
                                title: "Скачать файл подписи",
                                variant: "outline",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.download(
                                    disagreement.customer_signature.md5,
                                    true,
                                    disagreement.customer_stored_document.name
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  : _vm._e(),
              ],
              2
            )
          }),
          1
        ),
      ]),
      _vm.signData.signature
        ? _c("show-sign-modal", {
            attrs: {
              signature: _vm.signData.signature,
              "org-is-customer": _vm.signData.isCustomer,
              show: _vm.showSignModal,
            },
            on: {
              close: function ($event) {
                _vm.showSignModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }