<template>
    <div>
        <b-row>
            <b-col md="10">
                <text-header id="atmo-contract">Договор</text-header>
            </b-col>
        </b-row>
        <purchase-contract-info :purchase="purchase" />
    </div>
</template>

<script>
import PurchaseContractInfo from '@/components/products/commercial/partials/purchase-contract-info.vue';

export default {
    name: 'public-commercial-contract-block',
    components: { PurchaseContractInfo },
    props: {
        purchase: {
            type: Object,
            required: true
        }
    }
};
</script>
