export default [
    {
        path: '/cabinet',
        component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/Cabinet'),
        name: 'Cabinet',
        children: [
            {
                path: 'ktru/favorites',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/common/ktru/favorites/KtruFavoritesList.vue'),
                name: 'KtruFavoritesList'
            },
            {
                path: 'ktru/requests',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/common/ktru/requests/KtruRequestsList.vue'),
                name: 'KtruRequestsList'
            },
            {
                path: 'ktru/requests/create',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/common/ktru/requests/KtruRequestsCreate.vue'),
                name: 'KtruRequestsCreate'
            },
            {
                path: 'ktru/requests/:id',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/common/ktru/requests/KtruRequestsCreate.vue'),
                name: 'KtruRequestsEdit'
            },
            {
                path: 'notifications',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/common/Notifications'),
                name: 'NotificationsList'
            },
            {
                path: 'notifications/settings',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/common/NotificationSettings'),
                name: 'NotificationSettings'
            },
            {
                path: 'organization/users',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/common/users/UsersList'),
                name: 'UsersList'
            },
            {
                path: 'organization/fl44-limits',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/customer/Fl44Limits/Fl44LimitsList'),
                name: 'CabinetFl44Limits'
            },
            {
                path: 'organization/users/create',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/common/users/UserCreate'),
                name: 'UserCreate'
            },
            {
                path: 'organization/users/:id',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/common/users/UserEdit'),
                name: 'UserEdit'
            },
            {
                path: 'user_profile',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/common/UserProfile'),
                name: 'UserProfile'
            },
            {
                path: 'change_cert',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/common/user-change-certificate.vue'),
                name: 'UserChangeCertificate'
            },
            {
                path: 'organization/profile',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/common/OrganizationData'),
                name: 'OrganizationData'
            },
            {
                path: 'account',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/provider/Account'),
                name: 'ProviderAccountCabinet'
            },
            {
                path: 'account/refund_request_create',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/provider/accounting/refund-request-create'),
                name: 'CabinetProviderAccountingRefundRequestsCreate'
            },
            {
                path: 'fl44/purchases',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/customer/Purchases'),
                name: 'CustomerPurchasesCabinet'
            },
            { path: 'fl44/contracts_customer', redirect: 'fl44/contracts_customer/all' },
            {
                path: 'fl44/contracts_customer/:cat',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/customer/contracts/customer-contracts-list'),
                name: 'CustomerContractsCabinet'
            },
            {
                path: 'fl44/contracts_provider',
                redirect: 'fl44/contracts_provider/all',
                name: 'ProviderContractsAllCabinet'
            },
            {
                path: 'fl44/contracts_provider/:cat',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/provider/contracts/provider-contracts-list.vue'),
                name: 'ProviderContractsCabinet'
            },
            {
                path: 'fl44/proposals',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/fl44/cabinet-fl44-proposals-list.vue'),
                name: 'CabinetFl44ProposalsList'
            },
            {
                path: 'fl44/purchase_reports',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/fl44/purchase-reports/fl44-purchase-reports-list.vue'),
                name: 'CabinetFl44PurchaseReports'
            },
            {
                path: 'fl223/contracts',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/fl223/cabinet-fl223-contracts-list.vue'),
                name: 'CabinetFl223Contracts'
            },
            {
                path: 'fl223/notices',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/fl223/cabinet-fl223-notices-list.vue'),
                name: 'CabinetFl223Notices'
            },
            {
                path: 'fl223/proposals',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/fl223/cabinet-fl223-proposals-list.vue'),
                name: 'CabinetFl223ProposalsList'
            },
            {
                path: 'fl223/settings',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/fl223/cabinet-fl223-settings.vue'),
                name: 'CabinetFl223Settings'
            },
            {
                path: 'commercial/contracts',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/commercial/cabinet-commercial-contracts-list.vue'),
                name: 'CabinetCommercialContracts'
            },
            {
                path: 'commercial/notices',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/commercial/cabinet-commercial-notices-list.vue'),
                name: 'CabinetCommercialNotices'
            },
            {
                path: 'commercial/proposals',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/commercial/cabinet-commercial-proposals-list.vue'),
                name: 'CabinetCommercialProposalsList'
            },
            {
                path: 'reliability-report',
                component: () => import(/* webpackChunkName: "main" */ 'themes/views/pages/client-space/supplier/organization-reliability-report/OrganizationReliabilityReport.view.vue'),
                name: 'OrganizationReliabilityReport'
            },
            {
                path: 'quotation_requests/offers/:cat',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/common/quotation-requests/OffersList.vue'),
                name: 'QuotationRequestsOffersList'
            },
            {
                path: 'quotation_requests/requests/:cat',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/common/quotation-requests/RequestsList.vue'),
                name: 'QuotationRequestsRequestsList'
            },
            {
                path: 'productions',
                component: () => import('@/components/cabinets/provider/productions/production-list'),
                name: 'ProviderProductionsList'
            },
            {
                path: 'productions/create',
                component: () => import(/* webpackChunkName: "provider-production-create" */ '@/components/cabinets/provider/productions/production-create'),
                name: 'ProviderProductionsCreate'
            },
            {
                path: 'productions/:id',
                component: () => import(/* webpackChunkName: "provider-production-edit" */ '@/components/cabinets/provider/productions/production-edit'),
                name: 'ProviderProductionsEdit'
            },
            {
                path: 'addresses',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/common/Addresses'),
                name: 'PersonalAddressesCabinet'
            }
        ]
    }
];
