var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("input", {
        ref: "fileload",
        staticStyle: { display: "none" },
        attrs: {
          type: "file",
          id: "fileload",
          accept: ".doc,.docx,.pdf,.jpg,.jpeg,.xls,.xlsx,.zip",
        },
        on: { change: _vm.onChangeFileUpload },
      }),
      _c(
        "b-button",
        {
          attrs: {
            variant: "custom-green",
            disabled: _vm.isDisabled || _vm.uploading,
          },
          on: { click: _vm.fileClick },
        },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }