var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !!_vm.to
    ? _c(
        "b-button",
        {
          staticClass: "fs-14",
          staticStyle: {
            "line-height": "21px",
            "padding-right": "9px",
            "padding-left": "9px",
          },
          attrs: {
            title: _vm.title,
            variant: _vm.variant,
            to: _vm.to,
            disabled: _vm.disabled,
          },
        },
        [
          _c("b-icon", {
            attrs: { icon: _vm.icon, scale: _vm.scale, "aria-hidden": "true" },
          }),
        ],
        1
      )
    : _c(
        "b-button",
        {
          staticClass: "fs-14",
          staticStyle: {
            "line-height": "21px",
            "padding-right": "9px",
            "padding-left": "9px",
          },
          attrs: {
            title: _vm.title,
            variant: _vm.variant,
            disabled: _vm.disabled,
          },
          on: { click: _vm.click },
        },
        [
          _c("b-icon", {
            attrs: { icon: _vm.icon, scale: _vm.scale, "aria-hidden": "true" },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }