var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        visible: _vm.show,
        "hide-header-close": "",
        centered: "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        size: "md",
        title: _vm.title,
      },
      on: { show: _vm.sign, close: _vm.close, hide: _vm.close },
    },
    [
      _c("h6", { staticClass: "fw-600 mb-0" }, [_vm._v("Файл")]),
      _c(
        "a",
        {
          staticClass: "system_link-style fs-14 fw-400",
          attrs: {
            href: _vm.$getAttachmentDownloadLink(_vm.attachment.id),
            target: "_blank",
          },
        },
        [_vm._v(_vm._s(_vm.attachment.name))]
      ),
      _c("h6", { staticClass: "fw-600 mt-1" }, [_vm._v("Подпись")]),
      _c("div", { staticClass: "signature" }, [
        _c("pre", { staticClass: "sign-text" }, [
          _vm._v("            " + _vm._s(_vm.signature) + "\n        "),
        ]),
      ]),
      _vm.withComment
        ? [
            _c(
              "b-overlay",
              {
                attrs: {
                  show: _vm.isSigning || _vm.isDataSending,
                  rounded: "",
                  opacity: "0.6",
                  "spinner-small": "",
                  "spinner-variant": "primary",
                },
              },
              [
                _c("form-row-edit-textarea", {
                  attrs: {
                    label: "Комментарий",
                    v: _vm.$v.form.comment,
                    "label-on-top": "",
                    compact: "",
                  },
                  model: {
                    value: _vm.form.comment,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "comment", $$v)
                    },
                    expression: "form.comment",
                  },
                }),
              ],
              1
            ),
          ]
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "w-100 text-right",
          attrs: { slot: "modal-footer" },
          slot: "modal-footer",
        },
        [
          _c(
            "b-overlay",
            {
              attrs: {
                show: _vm.isSigning || _vm.isDataSending,
                rounded: "",
                opacity: "0.6",
                "spinner-small": "",
                "spinner-variant": "primary",
              },
            },
            [
              _c(
                "b-button",
                {
                  staticClass: "text-uppercase mr-2",
                  attrs: { variant: "custom-outline-secondary" },
                  on: { click: _vm.close },
                },
                [_vm._v("Отмена")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "text-uppercase",
                  attrs: {
                    variant: "custom-green",
                    disabled: !_vm.signature || _vm.$v.form.$invalid,
                  },
                  on: { click: _vm.send },
                },
                [_vm._v(_vm._s(_vm.submitTitle))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }