import { render, staticRenderFns } from "./disagreement-table.vue?vue&type=template&id=4f94f41d&"
import script from "./disagreement-table.vue?vue&type=script&lang=js&"
export * from "./disagreement-table.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/stage-dev/front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4f94f41d')) {
      api.createRecord('4f94f41d', component.options)
    } else {
      api.reload('4f94f41d', component.options)
    }
    module.hot.accept("./disagreement-table.vue?vue&type=template&id=4f94f41d&", function () {
      api.rerender('4f94f41d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/products/fl223/partials/disagreement-table.vue"
export default component.exports