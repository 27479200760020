var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.noticeitem.actions.supplier?.proposal.create ||
    _vm.noticeitem.actions.supplier.proposal.edit
    ? _c(
        "section",
        [
          _vm.hasRestrictions
            ? [
                _vm.noticeitem.actions.supplier.proposal.create
                  ? _c(
                      "b-button",
                      {
                        staticClass:
                          "btn-card-action btn-card-action-green text-uppercase my-0",
                        attrs: {
                          id: "popover-commercial-" + _vm.noticeitem.id,
                          target: "_blank",
                        },
                      },
                      [_vm._v(" Подать предложение")]
                    )
                  : _vm._e(),
                _vm.noticeitem.actions.supplier.proposal.edit
                  ? _c(
                      "b-button",
                      {
                        staticClass:
                          "btn-card-action btn-card-action-orange text-uppercase my-0",
                        attrs: {
                          id: "popover-commercial-" + _vm.noticeitem.id,
                          target: "_blank",
                        },
                      },
                      [_vm._v(" Изменить предложение")]
                    )
                  : _vm._e(),
                _c(
                  "b-popover",
                  {
                    attrs: {
                      target: "popover-commercial-" + _vm.noticeitem.id,
                      triggers: "hover",
                    },
                  },
                  [
                    _vm.noticeitem.restrictions.money.status
                      ? _c(
                          "p",
                          { staticClass: "fs-12 my-1 py-1" },
                          [
                            _vm._v(
                              " На лицевом счете недостаточно средств. Для подачи предложения не хватает "
                            ),
                            _c(
                              "span",
                              { staticClass: "text-danger text-nowrap" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$formatPrice(
                                      _vm.noticeitem.restrictions.money
                                        .not_enough
                                    )
                                  )
                                ),
                              ]
                            ),
                            _vm._v(" руб."),
                            _c("br"),
                            _c(
                              "router-link",
                              {
                                staticClass: "system_link-style",
                                attrs: {
                                  to: { name: "ProviderAccountCabinet" },
                                },
                              },
                              [_vm._v("Пополнить лицевой счет")]
                            ),
                            _vm._v(" . "),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.noticeitem.restrictions.smp.status
                      ? _c("p", { staticClass: "fs-12 my-1 py-1" }, [
                          _vm._v(
                            "Подача предложения невозможна, так как Ваша организация не является СМП."
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
              ]
            : [
                _vm.noticeitem.actions.supplier.proposal.create
                  ? _c(
                      "b-button",
                      {
                        staticClass:
                          "btn-card-action btn-card-action-green text-uppercase my-0",
                        attrs: {
                          to: {
                            name: "CommercialPurchaseNewProposal",
                            params: { id: _vm.noticeitem.id },
                          },
                          target: "_blank",
                        },
                      },
                      [_vm._v(" Подать предложение")]
                    )
                  : _vm._e(),
                _vm.noticeitem.actions.supplier.proposal.edit
                  ? _c(
                      "b-button",
                      {
                        staticClass:
                          "btn-card-action btn-card-action-orange text-uppercase my-0",
                        attrs: {
                          to: {
                            name: "CommercialPurchaseNewProposal",
                            params: { id: _vm.noticeitem.id },
                          },
                          target: "_blank",
                        },
                      },
                      [_vm._v(" Изменить предложение")]
                    )
                  : _vm._e(),
              ],
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }