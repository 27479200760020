import { CommercialOrganizationInformation } from '@/services/backend/products/commercial/organization-information';
import { CommercialPurchases } from '@/services/backend/products/commercial/purchases';
import { CommercialContracts } from '@/services/backend/products/commercial/contracts';
import { CommercialNotices } from '@/services/backend/products/commercial/notices';

export default {
    organizationInfo: new CommercialOrganizationInformation(),
    purchases: new CommercialPurchases(),
    contracts: new CommercialContracts(),
    notices: new CommercialNotices()
};
