var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      [
        _c(
          "b-row",
          [
            _c(
              "b-col",
              { attrs: { md: "10" } },
              [
                _c("text-header", { attrs: { id: "atmo-contract" } }, [
                  _vm._v("Договор"),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _vm.purchase.actions.customer.contract !== "done"
          ? _c(
              "div",
              { staticClass: "mb-2" },
              [
                _c(
                  "b-btn",
                  {
                    staticClass: "fs-14 my-1 text-uppercase",
                    staticStyle: { color: "white !important" },
                    attrs: {
                      href: _vm.$links.fl223_purchase_contract_customer_manual,
                      size: "lg",
                      target: "_blank",
                      variant: "danger",
                    },
                  },
                  [
                    _c("i", { staticClass: "atmo-icon-play" }),
                    _vm._v(" Инструкция по заключению договора "),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.purchase.contract
          ? [
              _c("purchase-contract-info", {
                attrs: { purchase: _vm.purchase },
              }),
              _c("contract-files-table", {
                attrs: {
                  contract: _vm.purchase.contract,
                  "download-zip": _vm.download,
                  "is-data-sending": _vm.isDataSending,
                },
              }),
              _vm.purchase.actions.customer.contract === "sign"
                ? _c("sign-contract-button", {
                    attrs: { "is-data-sending": _vm.isDataSending },
                    on: {
                      click: function ($event) {
                        _vm.showConfirmModal = true
                      },
                    },
                  })
                : _vm._e(),
              _vm.purchase.contract.disagreements.length > 0
                ? _c("disagreement-table", {
                    attrs: {
                      disagreements: _vm.purchase.contract.disagreements,
                    },
                  })
                : _vm._e(),
              _vm.purchase.actions.customer.contract === "reply"
                ? [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { md: "10" } },
                          [
                            _c(
                              "text-header",
                              { attrs: { id: "atmo-contract" } },
                              [_vm._v("Согласование разногласий по договору")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-overlay",
                      {
                        attrs: {
                          show: _vm.isDataSending,
                          opacity: "0.6",
                          rounded: "",
                          "spinner-small": "",
                          "spinner-variant": "primary",
                        },
                      },
                      [
                        _c("form-row-contract-upload", {
                          attrs: {
                            "label-cols-lg": 3,
                            v: _vm.$v.formDisagreement.contract,
                            title: "Новая редакция договора",
                          },
                          model: {
                            value: _vm.formDisagreement.contract,
                            callback: function ($$v) {
                              _vm.$set(_vm.formDisagreement, "contract", $$v)
                            },
                            expression: "formDisagreement.contract",
                          },
                        }),
                        _c("form-row-contract-upload", {
                          attrs: {
                            "label-cols-lg": 3,
                            v: _vm.$v.formDisagreement.disagreement,
                            title: "Ответ на протокол разногласий",
                          },
                          model: {
                            value: _vm.formDisagreement.disagreement,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formDisagreement,
                                "disagreement",
                                $$v
                              )
                            },
                            expression: "formDisagreement.disagreement",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-overlay",
                      {
                        attrs: {
                          show: _vm.isDataSending,
                          opacity: "0.6",
                          rounded: "",
                          "spinner-small": "",
                          "spinner-variant": "primary",
                        },
                      },
                      [
                        _c(
                          "b-btn",
                          {
                            staticClass: "mr-2",
                            attrs: {
                              disabled:
                                !_vm.$store.getters.getCanSign ||
                                _vm.$v.formDisagreement.$invalid,
                              variant: "custom-green",
                            },
                            on: {
                              click: function ($event) {
                                _vm.showDisagreementModal = true
                              },
                            },
                          },
                          [
                            _vm._v(
                              "Подписать и отправить ответ на протокол разногласий"
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    !_vm.$store.getters.getCanSign
                      ? [
                          _vm.$store.getters.getLoginType === "cert"
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "text-danger pl-3 pt-0 fs-14 w-50 my-auto",
                                },
                                [
                                  _vm._v(
                                    "Для действия требуется загрузить/заменить МЧД на странице: "
                                  ),
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "green-link",
                                      attrs: { to: { name: "UserProfile" } },
                                    },
                                    [_vm._v("Данные учетной записи")]
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "span",
                                {
                                  staticClass:
                                    "text-danger pl-3 pt-0 fs-14 w-50 my-auto",
                                },
                                [
                                  _vm._v(
                                    "Подписание возможно только при авторизации при помощи ЭЦП"
                                  ),
                                ]
                              ),
                        ]
                      : _vm._e(),
                    _vm.formDisagreement.disagreement
                      ? _c("sign-file-modal", {
                          attrs: {
                            attachment: _vm.formDisagreement.disagreement,
                            show: _vm.showDisagreementModal,
                            "comment-required": "",
                            title:
                              "Отправка подписанного ответа на протокол разногласий",
                            "with-comment": "",
                          },
                          on: {
                            close: function ($event) {
                              _vm.showDisagreementModal = false
                            },
                            submit: _vm.storeSignedDisagreement,
                          },
                        })
                      : _vm._e(),
                  ]
                : _vm._e(),
            ]
          : _vm.purchase.actions.customer.contract === "create"
          ? [
              _c(
                "b-overlay",
                {
                  attrs: {
                    show: _vm.isDataSending,
                    opacity: "0.6",
                    rounded: "",
                    "spinner-small": "",
                    "spinner-variant": "primary",
                  },
                },
                [
                  _c("form-row-contract-upload", {
                    attrs: {
                      "label-cols-lg": 3,
                      v: _vm.$v.form.attachment,
                      "send-title": "Отправить договор",
                      title: "Договор",
                      "with-send": "",
                    },
                    on: { send: _vm.send },
                    model: {
                      value: _vm.form.attachment,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "attachment", $$v)
                      },
                      expression: "form.attachment",
                    },
                  }),
                ],
                1
              ),
            ]
          : _vm._e(),
      ],
      _vm.purchase.contract
        ? [
            _c("sign-file-modal", {
              attrs: {
                attachment: _vm.purchase.contract.contract_files.find(
                  (file) => !file.is_archived
                ).contract_attachment,
                show: _vm.showConfirmModal,
                title: "Подписание договора заказчиком",
              },
              on: {
                close: function ($event) {
                  _vm.showConfirmModal = false
                },
                submit: _vm.storeSignedContract,
              },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }