<template>
    <div class="my-2">
        <div class="purchase_part">
            <div class="purchase_part-header" :class="proposalStatusColor">
                <b-row class="w-100 m-0">
                    <b-col lg="8" md="8" class="px-1">
                        <p class="purchase_part-header_number">
                            <router-link class="white-system_link-style" target="_blank" :to="{ name: 'CommercialProposalShow', params: { id: proposal.id } }">Предложение на коммерческую закупку № {{ proposal.purchase.reg_number }} ({{ proposal.purchase.status.title }})</router-link>
                        </p>
                    </b-col>
                    <b-col lg="4" md="4" class="px-1">
                        <p class="purchase_part-header_label fw-600 float-right text-right my-auto">
                            {{ proposal.status.title }}
                        </p>
                    </b-col>
                </b-row>
            </div>
            <div class="purchase_part-body row">
                <b-col md="6" class="purchase_part-body_col">
                    <div class="my-2">
                        <cabinet-notice-card-row-single v-if="proposal.purchase.status.id === 'accepting' && !!proposal.purchase.purchase_end_datetime" label="До окончания подачи предложений">
                            <timer-new :data="proposal.purchase.purchase_end_datetime" />
                        </cabinet-notice-card-row-single>
                        <cabinet-notice-card-row-single v-else label="Дата и время окончания приема предложений">
                            {{ getDateTimeFormat(proposal.purchase.purchase_end_datetime) }}
                        </cabinet-notice-card-row-single>
                        <cabinet-notice-card-row-single label="Заказчик">
                            <router-link :to="{ name: 'OrganizationShow', params: { id: proposal.customer.id } }" class="system_link-style fw-600 text-uppercase fs-12" target="_blank">
                                {{ proposal.customer.name }}
                            </router-link>
                        </cabinet-notice-card-row-single>
                        <cabinet-notice-card-row-single label="Объект закупки" v-if="proposal.purchase.purchase_object">
                            {{ proposal.purchase.purchase_object }}
                        </cabinet-notice-card-row-single>
                        <cabinet-notice-card-row-single label="Предмет закупки">
                            {{ proposal.purchase.deliverable_group_title }}
                        </cabinet-notice-card-row-single>
                        <cabinet-notice-card-row-single label="Сумма закупки"> {{ $formatPrice(proposal.purchase.starting_price) }} руб. </cabinet-notice-card-row-single>
                    </div>
                </b-col>
                <b-col md="6" class="purchase_part-body_col">
                    <div class="h-100 my-2">
                        <cabinet-notice-card-row-single label="Причина отклонения" v-if="proposal.status.id === 'rejected' && proposal.rejection_reason">
                            {{ proposal.rejection_reason }}
                        </cabinet-notice-card-row-single>
                        <cabinet-notice-card-row-single label="Дата и время подачи предложения">
                            {{ getDateTimeFormat(proposal.created_at) }}
                        </cabinet-notice-card-row-single>
                        <cabinet-notice-card-row-single label="Стоимость предложения"> {{ $formatPrice(proposal.price_with_vat) }} руб. </cabinet-notice-card-row-single>
                        <template v-if="$store.getters.isLoggedIn && proposal.actions.supplier.edit">
                            <router-link :to="{ name: 'CommercialPurchaseNewProposal', params: { id: proposal.purchase.id } }" class="btn btn-card-action btn-card-action-green my-1" target="_blank"> Изменить предложение </router-link>
                        </template>
                        <template v-if="$store.getters.isLoggedIn && proposal.actions.supplier.create">
                            <router-link :to="{ name: 'CommercialPurchaseNewProposal', params: { id: proposal.purchase.id } }" class="btn btn-card-action btn-card-action-green my-1" target="_blank"> Подать предложение </router-link>
                        </template>
                        <cabinet-commercial-proposal-card-contract-button :proposal="proposal" />
                    </div>
                </b-col>
            </div>
        </div>
    </div>
</template>

<script>
import TimerNew from '@/components/elements/TimerNew.vue';
import CabinetNoticeCardRowSingle from '@/components/cabinets/partials/cabinet-notice-card-row-single.vue';
import CabinetCommercialProposalCardContractButton from '@/components/cabinets/commercial/cards/partials/cabinet-commercial-proposal-card-contract-button.vue';

export default {
    name: 'cabinet-commercial-proposal-card',
    components: {
        CabinetCommercialProposalCardContractButton,
        CabinetNoticeCardRowSingle,
        TimerNew
    },
    props: ['proposal'],
    data() {
        return {};
    },
    methods: {
        refresh() {
            this.$emit('refresh');
        }
    },
    computed: {
        proposalStatusColor() {
            switch (this.proposal.status.id) {
                case 'actual':
                    return 'status_color_green';
                case 'withdrawn':
                    return 'status_color_grey';
                case 'rejected':
                case 'purchase_canceled':
                    return 'status_color_yellow';
                case 'winner':
                    return 'status_color_red';
                default:
                    return 'status_color_green';
            }
        }
    }
};
</script>

<style scoped>
.purchase_part {
    background: #fff;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    border: 1px solid #d9d9de;
}

.purchase_part-subheader {
    background-color: #e4e6e8;
    padding: 12px 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.purchase_part-body {
    padding: 5px 15px;
}

.purchase_part-body_col + .purchase_part-body_col > div::before {
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    background-color: #d9d9de;
    left: 0px;
    top: 0px;
}

@media screen and (max-width: 767px) {
    .purchase_part-body {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .purchase_part-body_col > div {
        position: relative;
    }

    .purchase_part-body_col > div::before {
        content: '';
        width: 100% !important;
        height: 1px !important;
        top: -12px;
    }

    .hidden-mobile {
        display: none;
    }

    .time_to_end {
        font-size: 12px !important;
        margin-bottom: 0 !important;
        /*margin-left: 5px !important;*/
    }

    /*.purchase_status {*/
    /*    margin-left: 5px !important;*/
    /*}*/
}

@media screen and (max-width: 768px) {
    .purchase_part-body_col::before {
        content: '';
        display: none;
    }
}

@media screen and (max-width: 420px) {
    .purchase_part-header {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -moz-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -moz-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        min-height: 65px;
    }
}
</style>
<style scoped>
.purchase_part-header {
    background-color: #959ba4;
    padding: 9px 11px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.purchase_part-header_number {
    font-size: 14px !important;
    color: #fff;
    margin: 0;
    font-weight: 600;
}
.purchase_part-header_label {
    text-transform: uppercase;
    color: #fff;
    margin: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 14px !important;
}
</style>
