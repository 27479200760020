import { render, staticRenderFns } from "./sign-contract-button.vue?vue&type=template&id=68123b80&"
import script from "./sign-contract-button.vue?vue&type=script&lang=js&"
export * from "./sign-contract-button.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/stage-dev/front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('68123b80')) {
      api.createRecord('68123b80', component.options)
    } else {
      api.reload('68123b80', component.options)
    }
    module.hot.accept("./sign-contract-button.vue?vue&type=template&id=68123b80&", function () {
      api.rerender('68123b80', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/products/commercial/partials/sign-contract-button.vue"
export default component.exports