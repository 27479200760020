var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("input", {
        ref: "fileload",
        staticStyle: { display: "none" },
        attrs: {
          type: "file",
          id: "fileload",
          accept: ".doc,.docx,.pdf,.jpg,.jpeg,.xls,.xlsx,.zip",
        },
        on: { change: _vm.onChangeFileUpload },
      }),
      _c(
        "b-button",
        {
          staticClass: "fixed-height",
          attrs: { variant: "custom-green-small", disabled: _vm.isDisabled },
          on: { click: _vm.fileClick },
        },
        [
          _c("b-icon", {
            staticClass: "my-auto mr-2",
            attrs: { icon: "paperclip", "aria-hidden": "true" },
          }),
          _vm._v("  "),
          _vm._t("default"),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }