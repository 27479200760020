var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "my-2" }, [
    _c("div", { staticClass: "purchase_part" }, [
      _c(
        "div",
        { staticClass: "purchase_part-header", class: _vm.statusColor },
        [
          _c(
            "b-row",
            { staticClass: "w-100 m-0" },
            [
              _c(
                "b-col",
                { staticClass: "px-1", attrs: { lg: "8", md: "8" } },
                [
                  _c(
                    "p",
                    {
                      staticClass: "purchase_part-header_number",
                      staticStyle: {
                        "white-space": "nowrap",
                        overflow: "hidden",
                        "text-overflow": "ellipsis",
                      },
                    },
                    [
                      _c("b-badge", { staticClass: "fs-14 mr-2" }, [
                        _vm._v(_vm._s(_vm.purchase.product.title)),
                      ]),
                      _vm.isPurchasePubliclyAvailable
                        ? [
                            _c(
                              "router-link",
                              {
                                staticClass: "white-system_link-style",
                                attrs: {
                                  to: {
                                    name: "CommercialPurchaseShow",
                                    params: { id: _vm.purchase.id },
                                  },
                                  target: "_blank",
                                },
                              },
                              [_vm._v(_vm._s(_vm.purchase.reg_number))]
                            ),
                          ]
                        : [
                            _c("span", [
                              _vm._v(_vm._s(_vm.purchase.reg_number)),
                            ]),
                          ],
                      _vm.purchase.purchase_object
                        ? _c(
                            "span",
                            {
                              staticStyle: {
                                "text-overflow": "ellipsis ellipsis",
                              },
                            },
                            [
                              _vm._v(
                                ' На закупку "' +
                                  _vm._s(_vm.purchase.purchase_object) +
                                  '"'
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              ),
              _c(
                "b-col",
                { staticClass: "px-1", attrs: { lg: "4", md: "4" } },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "purchase_part-header_label fw-600 float-right text-right my-auto",
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.purchase.status.title) + " "),
                      _c("i", {
                        staticClass: "atmo-icon ml-2",
                        class: _vm.statusIconClass,
                      }),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "purchase_part-subheader" },
        [
          _c(
            "b-col",
            {
              staticClass: "d-flex align-items-center",
              attrs: { lg: "3", sm: "6", xs: "12" },
            },
            [
              _c("span", { staticClass: "fs-11 green-color" }, [
                _vm._v("Сумма закупки: "),
                _c("span", { staticClass: "fw-600 ws-nowrap" }, [
                  _vm._v(
                    _vm._s(_vm.$formatPrice(_vm.purchase.start_price)) + " руб."
                  ),
                ]),
              ]),
            ]
          ),
          _vm.purchase.status.id !== "draft" &&
          _vm.purchase.status.id !== "signed" &&
          _vm.purchase.status.id !== "revoked" &&
          _vm.purchase.status.id !== "accepting"
            ? [
                _c(
                  "b-col",
                  {
                    staticClass: "d-flex align-items-center",
                    attrs: { lg: "3", sm: "6", xs: "12" },
                  },
                  [
                    _c("span", { staticClass: "fs-11 green-color" }, [
                      _vm._v("Предложений: "),
                      _c("span", { staticClass: "fw-600 ws-nowrap" }, [
                        _vm._v(
                          _vm._s(
                            _vm.purchase.proposals_count
                              ? _vm.purchase.proposals_count
                              : "-"
                          )
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "purchase_part-body row" },
        [
          _c(
            "b-col",
            { staticClass: "purchase_part-body_col", attrs: { md: "4" } },
            [
              _c(
                "div",
                { staticClass: "my-2" },
                [
                  _vm.purchase.status.id === "accepting" &&
                  !!_vm.purchase.purchase_end_datetime
                    ? _c(
                        "cabinet-notice-card-row-dual",
                        { attrs: { label: "До окончания подачи предложений" } },
                        [
                          _c("timer-new", {
                            attrs: { data: _vm.purchase.purchase_end_datetime },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.purchase.planned_contract_execution_datetime
                    ? _c(
                        "cabinet-notice-card-row-single",
                        { attrs: { label: "Планируемая дата исполнения" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.getDateFormat(
                                _vm.purchase.planned_contract_execution_datetime
                              )
                            )
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.purchase.flags.is_customer
                    ? _c("commercial-purchase-comment-edit-block", {
                        staticClass: "pb-2",
                        attrs: { purchase: _vm.purchase },
                        on: { refresh: _vm.refresh },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _c(
            "b-col",
            { staticClass: "purchase_part-body_col", attrs: { md: "4" } },
            [
              _c(
                "div",
                { staticClass: "my-2" },
                [
                  _vm.purchase.deliverable_group_title
                    ? _c(
                        "cabinet-notice-card-row-dual",
                        { attrs: { label: "Предмет закупки" } },
                        [_vm._v(_vm._s(_vm.purchase.deliverable_group_title))]
                      )
                    : _vm._e(),
                  _vm.purchase.delivery_addresses &&
                  _vm.purchase.delivery_addresses.length
                    ? _c(
                        "div",
                        { staticClass: "pb-2" },
                        [
                          _c("p", { staticClass: "fs-12 m-0 grey-color" }, [
                            _vm._v(
                              "Адреса для доставки товаров/выполнения работ/оказания услуг:"
                            ),
                          ]),
                          _vm._l(
                            _vm.purchase.delivery_addresses,
                            function (address) {
                              return _c(
                                "p",
                                {
                                  key: address,
                                  staticClass: "fs-12 m-0 grey-color fw-600",
                                },
                                [_vm._v("— " + _vm._s(address))]
                              )
                            }
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _c(
            "b-col",
            { staticClass: "purchase_part-body_col", attrs: { md: "4" } },
            [
              _c("div", { staticClass: "h-100" }, [
                _c(
                  "div",
                  { staticClass: "my-2" },
                  [
                    _vm.purchase.actions.customer.edit
                      ? _c(
                          "b-btn",
                          {
                            staticClass:
                              "btn-card-action btn-card-action-green my-1",
                            attrs: {
                              to: {
                                name: "CommercialPurchaseEdit",
                                params: { id: _vm.purchase.id },
                              },
                            },
                          },
                          [_vm._v(" Редактировать")]
                        )
                      : _vm._e(),
                    _c("cabinet-commercial-notice-card-publish-button", {
                      attrs: { purchase: _vm.purchase },
                      on: { publish: _vm.refresh },
                    }),
                    _c("cabinet-commercial-notice-card-cancel-button", {
                      attrs: { purchase: _vm.purchase },
                      on: { cancel: _vm.refresh },
                    }),
                    _c("cabinet-commercial-notice-card-unpublish-button", {
                      attrs: { purchase: _vm.purchase },
                      on: { unpublish: _vm.refresh },
                    }),
                    _vm.purchase.actions.customer.copy
                      ? _c(
                          "b-button",
                          {
                            staticClass:
                              "btn-card-action btn-card-action-green my-1",
                            on: { click: _vm.copyPurchase },
                          },
                          [_vm._v("Копировать")]
                        )
                      : _vm._e(),
                    _vm.purchase.status.id === "summarizing"
                      ? _c(
                          "b-button",
                          {
                            staticClass:
                              "btn-card-action btn-card-action-light-danger my-1",
                            attrs: {
                              to: {
                                name: "CommercialPurchaseShow",
                                params: { id: _vm.purchase.id },
                                hash: "#atmo-winner-select",
                              },
                              target: "_blank",
                            },
                          },
                          [_vm._v("Подвести итоги")]
                        )
                      : _vm._e(),
                    _c("cabinet-commercial-notice-card-contract-button", {
                      attrs: { purchase: _vm.purchase },
                    }),
                    _c("cabinet-commercial-notice-card-delete-button", {
                      attrs: { purchase: _vm.purchase },
                      on: { delete: _vm.refresh },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }