<template>
    <div>
        <b-btn v-if="purchase.actions.customer.contract === 'create'" class="btn-card-action btn-card-action-danger my-1" :to="{ name: 'CommercialPurchaseShow', params: { id: purchase.id }, hash: '#atmo-contract' }" target="_blank">Отправить договор</b-btn>
        <b-btn v-else-if="purchase.actions.customer.contract === 'sign'" class="btn-card-action btn-card-action-danger my-1" :to="{ name: 'CommercialPurchaseShow', params: { id: purchase.id }, hash: '#atmo-contract' }" target="_blank">Подписать договор</b-btn>
        <b-btn v-else-if="purchase.actions.customer.contract === 'reply'" class="btn-card-action btn-card-action-danger my-1" :to="{ name: 'CommercialPurchaseShow', params: { id: purchase.id }, hash: '#atmo-contract' }" target="_blank">Ответить на протокол разногласий</b-btn>
    </div>
</template>

<script>
export default {
    name: 'cabinet-commercial-notice-card-contract-button',
    props: {
        purchase: {
            type: Object
        }
    }
};
</script>
