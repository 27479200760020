<template>
    <div v-if="purchase">
        <p class="fs-12 m-0 grey-color text-break">
            <span class="mr-2" @click="showModal">
                <img src="/images/edit_icon.svg" alt="check_label" style="width: 16px; cursor: pointer" />
            </span>

            <b>Примечание: </b>
            <template v-if="comment">
                {{ comment }}
            </template>
            <template v-else> &mdash; </template>
        </p>
        <b-modal v-model="show" :title="'Редактирование примечания к закупке ' + purchase.reg_number" no-close-on-backdrop no-close-on-esc no-close-on-ok hide-footer @cancel="handleModalCancel" @close="handleModalCancel" @ok="handleModalAccept">
            <b-row class="mb-4">
                <b-col>
                    <form-row-edit-textarea label="Примечание к закупке" v-model="comment" :rows="8" />
                </b-col>
            </b-row>
            <b-row>
                <b-col class="text-right">
                    <b-btn variant="custom-outline-secondary" class="mr-2" @click="handleModalCancel">Отмена</b-btn>
                    <b-overlay :show="isDataUpdating" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block">
                        <b-btn variant="custom-green" @click="handleModalAccept">Сохранить</b-btn>
                    </b-overlay>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import Purchases from '@/services/api/Purchases';

export default {
    name: 'PurchaseCommentEditBlock',
    props: {
        purchase: {
            required: true,
            type: Object
        },
        type: {
            type: String,
            default: 'fl44'
        }
    },
    data() {
        return {
            show: false,
            isDataUpdating: false,
            comment: ''
        };
    },
    mounted() {
        this.comment = this.type === 'fl44' ? this.purchase.purchase_comment : this.purchase.comment;
    },
    methods: {
        showModal() {
            this.show = true;
            this.isDataUpdating = false;
            this.comment = this.type === 'fl44' ? this.purchase.purchase_comment : this.purchase.comment;
        },
        handleModalCancel() {
            this.comment = this.type === 'fl44' ? this.purchase.purchase_comment : this.purchase.comment;
            this.show = false;
            this.isDataUpdating = false;
        },
        async handleModalAccept() {
            this.isDataUpdating = true;
            this.type === 'fl44' ? await Purchases.updateComment(this.purchase.id, this.comment) : await this.$api.personal.fl44ExternalPurchases.updateComment(this.comment, this.purchase.id);
            this.show = false;
            this.isDataUpdating = false;
            this.$emit('refresh');
        }
    }
};
</script>
