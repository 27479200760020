import store from '@/store';
import axios from 'axios';
import { BaseFl223ApiService } from './_base';

export class Fl223Contracts extends BaseFl223ApiService {
    constructor() {
        super('purchases/fl223');
    }

    async store(id, attachment) {
        try {
            await axios.post(this.getUrl(`${id}/contracts`), {
                contract_attachment: { ...attachment }
            });
            await store.dispatch('showSuccessToast', 'Договор успешно отправлен');
            return true;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async storeSigned(id, contractId, path, payload) {
        try {
            await axios.put(this.getUrl(`${id}/contracts/${contractId}/${path}/store_signed`), {
                ...payload
            });
            await store.dispatch('showSuccessToast', 'Договор успешно подписан');
            return true;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async storeSignedDisagreement(id, contractId, path, payload, disagreementId = null) {
        try {
            await axios.post(this.getUrl(`${id}/contracts/${contractId}/${path}/${disagreementId ? disagreementId : 'disagreement'}`), {
                ...payload
            });
            await store.dispatch('showSuccessToast', 'Действие выполнено успешно');
            return true;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    downloadZip(id, contractId, documentId) {
        return axios.get(this.getUrl(`${id}/contracts/${contractId}/${documentId}`), { responseType: 'blob' });
    }
}
