<template>
    <section class="atmo-nested-section">
        <div class="table-responsive">
            <table class="atmo-content-table atmo-protocol-completion mb-0">
                <thead>
                    <tr>
                        <th class="atmo-ctc-place">№</th>
                        <th class="atmo-ctc-width-200">Поставщик</th>
                        <th class="atmo-ctc-price-total">Стоимость, руб.</th>
                        <th class="atmo-ctc-submit-date">Дата и время подачи</th>
                        <th class="atmo-ctc-center">Рейтинг ИС</th>
                        <th class="atmo-ctc-center">Рейтинг Контур.Фокус</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td v-text="index + 1" class="atmo-ctc-place"></td>
                        <td class="atmo-ctc-supplier-link atmo-ctc-width-200">
                            <router-link v-bind:to="{ name: 'OrganizationShow', params: { id: proposal.supplier.id } }" target="_blank" class="atmo-button-link">
                                <span v-if="proposal.supplier.isLocalRegion()"> <img src="@theme/assets/images/icons/local-supplier-badge.svg" alt="Local supplier badge" />&nbsp; </span>
                                {{ proposal.supplier.title_brief }}
                            </router-link>
                        </td>
                        <td class="atmo-ctc-price-total">{{ proposal.total_price | formatnumber }}</td>
                        <td class="atmo-ctc-submit-date">{{ proposal.submitted_at | formatdate('withSeconds') }}</td>
                        <td class="atmo-ctc-center">
                            <span v-if="proposal.supplier.ratings.atmo" v-text="proposal.supplier.ratings.atmo"></span>
                            <span v-else>Нет</span>
                        </td>
                        <td class="atmo-ctc-center">
                            <ul v-if="proposal.supplier.hasConturFocusRating()" class="atmo-contur-focus-ratings-list">
                                <li v-for="(value, index) in proposal.supplier.ratings.contur_focus" v-bind:key="index">
                                    <span v-bind:class="`atmo-${value}`" class="atmo-contur-focus-rating"></span>
                                </li>
                            </ul>
                            <span v-else>Нет</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <p v-if="proposal.is_declined"><b>Предложение отклонено.</b> Причина отклонения: <span v-text="proposal.decline_reason"></span></p>

        <on-off v-slot:default="onoff" v-bind:options="{ onEsc: false, onBody: false }">
            <div class="atmo-content-group pb-0">
                <ul class="atmo-buttons">
                    <li>
                        <button v-on:click.stop="onoff.toggle" class="atmo-buttons atmo-button--small mb-0">
                            <span v-if="onoff.ison">Скрыть</span>
                            <span v-else>Показать</span>&nbsp;подробности
                        </button>
                    </li>
                    <li>
                        <a :href="rnpCheckLink" class="atmo-buttons atmo-button-inverse--small atmo-dimmed mb-0" target="_blank"> Проверить в РНП </a>
                    </li>
                </ul>

                <ul v-if="onoff.ison" class="atmo-content-block-2-columns atmo-no-item-separator">
                    <li>
                        <dt>Контакты:</dt>
                        <dd>
                            <ul>
                                <li>ФИО:&nbsp;<b v-text="proposal.supplier.contacts.name"></b></li>
                                <li>
                                    Телефон:&nbsp;<a v-bind:href="`tel:+${proposal.supplier.contacts.phone}`">{{ $parsePhone(proposal.supplier.contacts.phone) }}</a>
                                </li>
                                <li>Email:&nbsp;<a v-bind:href="`mailto:${proposal.supplier.contacts.email}`" v-text="proposal.supplier.contacts.email"></a></li>
                            </ul>
                        </dd>
                    </li>
                    <li v-if="proposal.organization_card_attachment">
                        <dt>Карточка предприятия:</dt>
                        <dd>
                            <ul>
                                <li>
                                    <a :href="$getAttachmentDownloadLink(proposal.organization_card_attachment.file_id)" class="atmo-button-link" v-text="proposal.organization_card_attachment.name"></a>
                                </li>
                            </ul>
                        </dd>
                    </li>
                    <li>
                        <dt>Требования к поставщикам:</dt>
                        <dd>
                            <SupplierRequirements.view v-bind:requirements="proposal.supplier_requirements" />
                        </dd>
                    </li>
                    <li>
                        <dt>Дополнительные документы, приложенные поставщиком к предложению:</dt>
                        <dd>
                            <ul v-if="proposal.hasAttachments()">
                                <li v-for="(attachment, index) in proposal.attachments" v-bind:key="index">
                                    <button v-on:click="pivc().downloadFileAttachment(attachment)" class="atmo-button-link" v-text="attachment.name"></button>
                                </li>
                            </ul>
                            <span v-else>Отсутствуют</span>
                        </dd>
                    </li>
                </ul>
            </div>
        </on-off>

        <ul class="atmo-buttons mb-0">
            <li>
                <button v-on:click.stop="pivc().downloadOrganizationReport(proposal.supplier, $event)" class="atmo-button-inverse atmo-dimmed">Скачать аналитический отчет об организации</button>
                <div class="atmo-spinner"><img src="@themes/atmo/assets/images/loaders/spinner-sector.svg" alt="" /></div>
            </li>
            <li v-if="!proposal.is_declined">
                <button v-on:click.stop="pivc().invokeDeclineProposalPopup(proposal, purchaseitem.supplier_requirements, $event)" class="atmo-button atmo-accented">Отклонить</button>
                <div class="atmo-spinner"><img src="@themes/atmo/assets/images/loaders/spinner-sector.svg" alt="" /></div>
            </li>
        </ul>
    </section>
</template>

<script>
import SupplierRequirementsView from './SupplierRequirements.view.vue';
import OnOff from '@lib/js/src/vue/components/OnOffV6.component.js';
import PurchaseItemViewController from '@core/js/viewmodels/purchases/item/view/PurchaseItemViewController.viewmodel';

export default {
    inject: ['pivc'],
    created() {
        this.$root.$on(PurchaseItemViewController.proposalDeclinedEventId, () => {
            this.$forceUpdate();
        });
    },
    components: {
        'SupplierRequirements.view': SupplierRequirementsView,
        'on-off': new OnOff()
    },
    props: {
        proposal: { required: true, type: Object },
        purchaseitem: { required: true, type: Object },
        index: { required: true, type: Number }
    },
    computed: {
        rnpCheckLink() {
            return 'https://zakupki.gov.ru/epz/dishonestsupplier/search/results.html?searchString=' + this.proposal.supplier.inn;
        }
    }
};
</script>
