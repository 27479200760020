<template>
    <div>
        <template>
            <b-row>
                <b-col md="10">
                    <text-header id="atmo-contract">Договор</text-header>
                </b-col>
            </b-row>
            <div v-if="purchase.actions.customer.contract !== 'done'" class="mb-2">
                <b-btn :href="$links.fl223_purchase_contract_customer_manual" class="fs-14 my-1 text-uppercase" size="lg" style="color: white !important" target="_blank" variant="danger">
                    <i class="atmo-icon-play" />
                    Инструкция по заключению договора
                </b-btn>
            </div>
            <template v-if="purchase.contract">
                <purchase-contract-info :purchase="purchase" />
                <contract-files-table :contract="purchase.contract" :download-zip="download" :is-data-sending="isDataSending" />
                <sign-contract-button v-if="purchase.actions.customer.contract === 'sign'" :is-data-sending="isDataSending" @click="showConfirmModal = true" />
                <disagreement-table v-if="purchase.contract.disagreements.length > 0" :disagreements="purchase.contract.disagreements" />
                <template v-if="purchase.actions.customer.contract === 'reply'">
                    <b-row>
                        <b-col md="10">
                            <text-header id="atmo-contract">Согласование разногласий по договору</text-header>
                        </b-col>
                    </b-row>
                    <b-overlay :show="isDataSending" opacity="0.6" rounded spinner-small spinner-variant="primary">
                        <form-row-contract-upload v-model="formDisagreement.contract" :label-cols-lg="3" :v="$v.formDisagreement.contract" title="Новая редакция договора" />
                        <form-row-contract-upload v-model="formDisagreement.disagreement" :label-cols-lg="3" :v="$v.formDisagreement.disagreement" title="Ответ на протокол разногласий" />
                    </b-overlay>
                    <b-overlay :show="isDataSending" opacity="0.6" rounded spinner-small spinner-variant="primary">
                        <b-btn :disabled="!$store.getters.getCanSign || $v.formDisagreement.$invalid" class="mr-2" variant="custom-green" @click="showDisagreementModal = true">Подписать и отправить ответ на протокол разногласий</b-btn>
                    </b-overlay>
                    <template v-if="!$store.getters.getCanSign">
                        <span v-if="$store.getters.getLoginType === 'cert'" class="text-danger pl-3 pt-0 fs-14 w-50 my-auto">Для действия требуется загрузить/заменить МЧД на странице: <router-link :to="{ name: 'UserProfile' }" class="green-link">Данные учетной записи</router-link></span>
                        <span v-else class="text-danger pl-3 pt-0 fs-14 w-50 my-auto">Подписание возможно только при авторизации при помощи ЭЦП</span>
                    </template>
                    <sign-file-modal
                        v-if="formDisagreement.disagreement"
                        :attachment="formDisagreement.disagreement"
                        :show="showDisagreementModal"
                        comment-required
                        title="Отправка подписанного ответа на протокол разногласий"
                        with-comment
                        @close="showDisagreementModal = false"
                        @submit="storeSignedDisagreement" />
                </template>
            </template>
            <template v-else-if="purchase.actions.customer.contract === 'create'">
                <b-overlay :show="isDataSending" opacity="0.6" rounded spinner-small spinner-variant="primary">
                    <form-row-contract-upload v-model="form.attachment" :label-cols-lg="3" :v="$v.form.attachment" send-title="Отправить договор" title="Договор" with-send @send="send" />
                </b-overlay>
            </template>
        </template>
        <template v-if="purchase.contract">
            <sign-file-modal :attachment="purchase.contract.contract_files.find((file) => !file.is_archived).contract_attachment" :show="showConfirmModal" title="Подписание договора заказчиком" @close="showConfirmModal = false" @submit="storeSignedContract" />
        </template>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { getCertificate } from 'crypto-pro';
import ContractFilesTable from '@/components/products/fl223/partials/contract-files-table.vue';
import PurchaseContractInfo from '@/components/products/fl223/partials/purchase-contract-info.vue';
import SignContractButton from '@/components/products/fl223/partials/sign-contract-button.vue';
import FileDownload from 'js-file-download';
import DisagreementTable from '@/components/products/fl223/partials/disagreement-table.vue';
import FormRowContractUpload from '@/components/common/form-rows/form-row-contract-upload.vue';

export default {
    name: 'private-fl223-contract-customer-block',
    components: { FormRowContractUpload, DisagreementTable, SignContractButton, PurchaseContractInfo, ContractFilesTable },
    props: {
        purchase: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            showConfirmModal: false,
            showDisagreementModal: false,
            form: {
                attachment: null
            },
            formDisagreement: {
                contract: null,
                disagreement: null
            },
            isDataSending: false
        };
    },
    validations() {
        return {
            form: {
                attachment: {
                    required
                }
            },
            formDisagreement: {
                contract: {
                    required
                },
                disagreement: {
                    required
                }
            }
        };
    },
    methods: {
        async send() {
            this.isDataSending = true;
            if (await this.$api.products.fl223.contracts.store(this.purchase.id, this.form.attachment)) {
                this.$emit('refresh');
            }
            this.isDataSending = false;
        },
        async storeSignedContract(signature) {
            this.isDataSending = true;
            const data = {
                certificate: await getCertificate(this.$store.getters.getCurrentThumbprint),
                signed_attachment: {
                    id: this.purchase.contract.contract_files.find((file) => !file.is_archived).contract_attachment.id,
                    md5: signature.signature
                }
            };
            if (await this.$api.products.fl223.contracts.storeSigned(this.purchase.id, this.purchase.contract.id, 'customer', data)) {
                this.showConfirmModal = false;
                this.$emit('refresh');
            }
            this.isDataSending = false;
        },
        async storeSignedDisagreement(signature) {
            this.isDataSending = true;
            const data = {
                certificate: await getCertificate(this.$store.getters.getCurrentThumbprint),
                comment: signature.comment,
                contract_attachment_id: this.formDisagreement.contract.id,
                signed_attachment: {
                    id: this.formDisagreement.disagreement.id,
                    md5: signature.signature
                }
            };
            const disagreementId = this.purchase.contract.disagreements.find((disagreement) => !disagreement.customer_stored_document).id;
            if (await this.$api.products.fl223.contracts.storeSignedDisagreement(this.purchase.id, this.purchase.contract.id, 'customer', data, disagreementId)) {
                this.showDisagreementModal = false;
                this.$emit('refresh');
            }
            this.isDataSending = false;
        },
        download() {
            this.$api.products.fl223.contracts
                .downloadZip(this.purchase.id, this.purchase.contract.id, this.purchase.contract.contract_files.find((file) => !file.is_archived).contract_attachment.id)
                .then((response) => {
                    FileDownload(response.data, this.purchase.contract.zip_name);
                })
                .catch((error) => {
                    this.showError(error);
                });
        }
    }
};
</script>
