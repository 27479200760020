var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    [
      _c(
        "b-row",
        [
          _c("b-col", [
            _c(
              "p",
              { staticClass: "my-2 text-center" },
              [
                _c(
                  "router-link",
                  {
                    staticClass:
                      "text-uppercase fw-600 grey-color text-decoration-none fs-14",
                    attrs: {
                      to: {
                        name: "notices.fl44",
                        query: { status: "accepting" },
                      },
                    },
                  },
                  [_vm._v("44-ФЗ Извещения ")]
                ),
              ],
              1
            ),
            _c(
              "p",
              { staticClass: "my-2 text-center" },
              [
                _c(
                  "router-link",
                  {
                    staticClass:
                      "text-uppercase fw-600 grey-color text-decoration-none fs-14",
                    class:
                      _vm.$route.matched[0]?.path === "/contracts/fl44" &&
                      "router-link-active",
                    attrs: {
                      to: {
                        name: "ContractsList",
                        params: { contract: "all" },
                      },
                    },
                  },
                  [_vm._v("44-ФЗ Контракты ")]
                ),
              ],
              1
            ),
            _c(
              "p",
              { staticClass: "my-4 text-center" },
              [
                _c(
                  "router-link",
                  {
                    staticClass:
                      "text-uppercase fw-600 grey-color text-decoration-none fs-14",
                    class:
                      _vm.$route.matched[0]?.path === "/notices/fl223" &&
                      "router-link-active",
                    attrs: {
                      to: {
                        name: "Fl223NoticesList",
                        params: { status: "accepting" },
                      },
                    },
                  },
                  [_vm._v("223-ФЗ Извещения ")]
                ),
              ],
              1
            ),
            _c(
              "p",
              { staticClass: "my-4 text-center" },
              [
                _c(
                  "router-link",
                  {
                    staticClass:
                      "text-uppercase fw-600 grey-color text-decoration-none fs-14",
                    class:
                      _vm.$route.matched[0]?.path === "/notices/commercial" &&
                      "router-link-active",
                    attrs: {
                      to: {
                        name: "CommercialNoticesList",
                        params: { status: "accepting" },
                      },
                    },
                  },
                  [_vm._v("Коммерческие извещения ")]
                ),
              ],
              1
            ),
            _c(
              "p",
              { staticClass: "my-4 text-center" },
              [
                _c(
                  "router-link",
                  {
                    staticClass:
                      "text-uppercase fw-600 grey-color text-decoration-none fs-14",
                    attrs: { to: "/quotation-requests" },
                  },
                  [_vm._v("Запросы КП ")]
                ),
              ],
              1
            ),
            _c(
              "p",
              { staticClass: "my-4 text-center" },
              [
                _c(
                  "router-link",
                  {
                    staticClass:
                      "text-uppercase fw-600 grey-color text-decoration-none fs-14",
                    attrs: {
                      to: {
                        name: "ImportSubstitutionRequestsList",
                        params: { path: "open" },
                      },
                    },
                  },
                  [_vm._v("Импортозамещение")]
                ),
              ],
              1
            ),
            _c(
              "p",
              { staticClass: "my-4 text-center" },
              [
                _c(
                  "router-link",
                  {
                    staticClass:
                      "text-uppercase fw-600 grey-color text-decoration-none fs-14",
                    attrs: { to: "/organizations" },
                  },
                  [_vm._v("ЗАКАЗЧИКИ И ПОСТАВЩИКИ ")]
                ),
              ],
              1
            ),
            _vm.$links.marketplace_link
              ? _c("p", { staticClass: "my-4 text-center" }, [
                  _c(
                    "a",
                    {
                      staticClass:
                        "atmo-link-off text-uppercase fw-600 grey-color text-decoration-none fs-14",
                      attrs: {
                        href: _vm.$links.marketplace_link,
                        target: "_blank",
                      },
                    },
                    [_vm._v(" БАШМАРКЕТ ")]
                  ),
                ])
              : _vm._e(),
            _c("p", { staticClass: "my-4 text-center" }, [
              _c(
                "a",
                {
                  staticClass:
                    "atmo-link-off text-uppercase fw-600 grey-color text-decoration-none fs-14",
                  attrs: { href: _vm.$links.info_materials, target: "_blank" },
                },
                [_vm._v(" ИНФОРМАЦИОННЫЕ МАТЕРИАЛЫ ")]
              ),
            ]),
            _c(
              "div",
              { staticClass: "d-flex flex-column align-items-center" },
              [
                !!_vm.$store.state.token
                  ? _c(
                      "router-link",
                      {
                        staticClass:
                          "text-uppercase text-nowrap btn btn-custom-green",
                        attrs: {
                          tag: "button",
                          exact: "",
                          to: { name: "Cabinet" },
                        },
                      },
                      [_vm._v("Личный кабинет ")]
                    )
                  : _vm._e(),
                !_vm.$store.state.token
                  ? _c(
                      "b-btn",
                      {
                        staticClass: "text-nowrap text-uppercase",
                        attrs: { variant: "custom-green" },
                        on: { click: _vm.goToLogin },
                      },
                      [_vm._v("Вход в ЛК")]
                    )
                  : _vm._e(),
                !!_vm.$store.state.token
                  ? _c(
                      "b-btn",
                      {
                        staticClass: "text-nowrap text-uppercase mt-2",
                        attrs: { variant: "custom-outline-secondary" },
                        on: { click: _vm.logout },
                      },
                      [_vm._v("Выйти")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }