<template>
    <b-container class="mt-4 purchase_part px-0">
        <b-card :header-class="`atmo-override-color atmo-entity-item-title ${cardCssClass} pr-2 position-relative`" body-class="purchase_part-body p-0" border-variant="light">
            <template #header>
                <b-row class="w-100">
                    <b-col class="text-nowrap purchase-header-text">
                        <b-badge class="my-auto fs-14 mr-2" variant="primary">{{ notice.product.title }}</b-badge>
                        <b-badge class="my-auto fs-14 mr-2" variant="custom-orange" v-if="notice.only_smp">Только СМП</b-badge>
                        <router-link :to="{ name: 'Fl223PurchaseShow', params: { id: notice.id } }" exact target="_blank">№ {{ notice.registration_number }}</router-link>
                        <template v-if="notice.purchase_object"> На закупку "{{ notice.purchase_object }}"</template>
                    </b-col>
                    <b-col class="text-nowrap text-uppercase status-title" md="auto">
                        {{ notice.status.title }}
                    </b-col>
                </b-row>
                <b-icon :icon="cardIcon" class="card-icon" />
            </template>
            <div class="purchase_part-subheader">
                <b-col class="d-flex align-items-center" lg="3" sm="6" xs="12">
                    <span class="fs-14 green-color">
                        Сумма закупки: <span class="fw-600">{{ $formatPrice(notice.starting_price) }} руб.</span>
                    </span>
                </b-col>
                <b-col class="d-flex align-items-center" lg="3" sm="6" xs="12">
                    <span class="fs-14 green-color">
                        Предложений:
                        <span v-if="!notice.are_proposals_hidden" v-text="notice.proposals_count" class="fw-600" />
                        <span v-else class="fw-600">Скрыто</span>
                    </span>
                </b-col>
                <b-col class="d-flex align-items-center" lg="3" sm="6" xs="12">
                    <span class="fs-14 green-color">
                        Лучшее:
                        <template v-if="!notice.are_proposals_hidden">
                            <span v-if="notice.best_proposal_price > 0" class="fw-600">{{ $formatPrice(notice.best_proposal_price) }}&nbsp;руб.</span>
                            <span v-else class="fw-600">-</span>
                        </template>
                        <span v-else class="fw-600">Скрыто</span>
                    </span>
                </b-col>
                <b-col class="d-flex align-items-center" lg="3" sm="6" xs="12">
                    <span class="fs-14 green-color">
                        Снижение:
                        <span v-if="!notice.are_proposals_hidden" class="fw-600">{{ notice.discount_percentage }}%</span>
                        <span v-else class="fw-600">Скрыто</span>
                    </span>
                </b-col>
            </div>
            <b-row class="purchase_part-body py-2">
                <b-col class="purchase_part-body_col" md="3">
                    <div>
                        <template v-if="notice.status.id === 'accepting'">
                            <p class="fs-14 m-0 grey-color my-1">
                                До окончания приёма предложений:
                                <timer-new :data="notice.proposal_accept_end_date" @timeout="isProposalTimedOut = true" />
                            </p>
                            <fl223-new-proposal-action-button v-if="!isProposalTimedOut && $store.getters.isLoggedIn" :noticeitem="notice" />
                        </template>
                        <p v-if="notice.status.id === 'summarizing'" class="fs-14 m-0 grey-color my-1">Прием предложений завершен</p>
                        <p v-if="notice.status.id === 'summarized'" class="fs-14 m-0 grey-color my-1">
                            Подведены итоги: <span class="fw-600">{{ getDateTimeFormat(notice.protocol_date) }}</span>
                        </p>
                        <p v-if="notice.status.id === 'failed'" class="fs-14 m-0 grey-color my-1">
                            Подведены итоги: <span class="fw-600">{{ getDateTimeFormat(notice.proposal_accept_end_date) }}</span>
                        </p>
                        <p v-if="notice.status.id === 'contract:active' || notice.status.id === 'contract:fulfilled' || notice.status.id === 'contract:unfulfilled' || notice.status.id === 'contract:terminated'" class="fs-14 m-0 grey-color my-1">
                            Договор заключен: <span class="fw-600">{{ getDateFormat(notice.contract.contract_conclusion_date) }}</span>
                        </p>
                        <p v-if="notice.contract?.price" class="fs-14 m-0 grey-color my-1">
                            Цена договора: <span class="fw-600">{{ $formatPrice(notice.contract.price) }} руб.</span>
                        </p>
                        <p v-if="!!notice.planned_date_contract_fulfilled" class="fs-14 m-0 grey-color my-1">
                            Планируемая дата исполнения: <span class="fw-600">{{ getDateFormat(notice.planned_date_contract_fulfilled) }}</span>
                        </p>
                        <p class="fs-14 m-0 grey-color my-1">
                            Количество позиций: <span class="fw-600">{{ notice.deliverables_count }}</span>
                        </p>
                    </div>
                </b-col>
                <b-col class="purchase_part-body_col" md="6">
                    <div>
                        <p class="fs-14 m-0 grey-color my-1">
                            Предмет закупки: <span class="fw-600">{{ notice.deliverable_group_title }}</span>
                        </p>
                        <p class="fs-14 m-0 grey-color my-1">
                            Заказчик:
                            <span class="fw-600">
                                <router-link :to="'/organizations/' + notice.customer.id" class="system_link-style text-uppercase fs-14" target="_blank">
                                    {{ notice.customer.name }}
                                </router-link>
                            </span>
                        </p>
                        <p v-if="notice.contract" class="fs-14 m-0 grey-color my-1">
                            Поставщик:
                            <span class="fw-600">
                                <router-link :to="'/organizations/' + notice.contract.supplier_id" class="system_link-style text-uppercase fs-14" target="_blank">
                                    {{ notice.contract.supplier_name }}
                                </router-link>
                            </span>
                        </p>
                        <template v-if="notice.delivery_addresses && notice.delivery_addresses.length > 0">
                            <p class="fs-14 m-0 grey-color my-1">Адреса доставки или самовывоза:</p>
                            <ul style="padding-left: 20px; list-style-type: disc !important">
                                <li v-for="(address, index) in notice.delivery_addresses" :key="index" class="fs-14 m-0 grey-color fw-600">
                                    {{ address }}
                                </li>
                            </ul>
                        </template>
                    </div>
                </b-col>
                <b-col class="purchase_part-body_col atmo-column atmo-special-style" md="3">
                    <div>
                        <p class="d-flex align-items-center green-color fs-14 fw-600 my-1">
                            <i class="atmo-icon-search link-icon"></i>
                            <router-link :to="{ name: 'Fl223PurchaseShow', params: { id: notice.id } }" class="system_link-style" exact target="_blank">Информация о закупке</router-link>
                        </p>
                        <p class="d-flex align-items-center green-color fs-14 fw-600 my-1" v-if="notice.status.id === 'contract:active'">
                            <i class="atmo-icon-contract link-icon"></i>
                            <router-link :to="{ name: 'Fl223PurchaseShow', params: { id: notice.id }, hash: '#atmo-contract' }" class="system_link-style" exact target="_blank">Договор</router-link>
                        </p>
                    </div>
                </b-col>
            </b-row>
        </b-card>
    </b-container>
</template>

<script>
import TimerNew from '@/components/elements/TimerNew.vue';
import Fl223NewProposalActionButton from '@/components/global/fl223-notices/partials/fl223-new-proposal-action-button.vue';

export default {
    name: 'fl223-notice-card',
    components: { Fl223NewProposalActionButton, TimerNew },
    props: {
        notice: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            addressesVisible: false,
            isProposalTimedOut: true
        };
    },
    mounted() {
        this.isProposalTimedOut = this.notice.status.id !== 'accepting';
    },
    computed: {
        cardCssClass() {
            switch (this.notice.status.id) {
                case 'accepting':
                case 'summarizing':
                    return 'card-header-grey';
                case 'contract:active':
                    return 'card-header-blue';
                case 'failed':
                case 'cancelled':
                    return 'card-header-yellow';
                default:
                    return 'card-header-green';
            }
        },
        cardIcon() {
            switch (this.notice.status.id) {
                case 'accepting':
                    return 'stopwatch';
                case 'summarizing':
                    return 'check-circle';
                case 'contract:active':
                    return 'file-earmark-ruled';
                case 'summarized':
                    return 'shield-check';
                case 'failed':
                case 'cancelled':
                    return 'x-circle';
                default:
                    return '';
            }
        }
    }
};
</script>

<style scoped>
.status-title {
    padding-top: 1px;
}

.link-icon {
    padding-right: 5px;
    color: #d5d5d5;
    font-size: medium;
}

.card-icon {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
}

.purchase_part {
    background: #fff;
}

.card-header-grey {
    background-color: #959ba4 !important;
}

.card-header-green {
    background-color: #26b89a !important;
}

.card-header-blue {
    background-color: #40acb4 !important;
}

.card-header-yellow {
    background-color: #f6b33a !important;
}

.purchase-header-text {
    text-overflow: ellipsis;
    overflow: hidden;
}

.purchase_part-header {
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
    background-color: var(--green-color);
    padding: 9px 8px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.purchase_part-subheader {
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
    background-color: #e4e6e8;
    padding: 12px 8px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.purchase_part-body {
    padding: 14px 26px;
}

.purchase_part-header_number {
    font-size: 14px;
    color: #fff;
    margin: 0;
    font-weight: 600;
}

.purchase_part-header_label {
    text-transform: uppercase;
    color: #fff;
    margin: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 12px;
}

.purchase_part-body_col + .purchase_part-body_col > div::before {
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    background-color: #d9d9de;
    left: 0px;
    top: 0px;
}

@media screen and (max-width: 767px) {
    .purchase_part-body {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .purchase_part-body_col > div::before {
        display: none;
    }

    .hidden-mobile {
        display: none;
    }

    .time_to_end {
        font-size: 12px !important;
        margin-bottom: 0 !important;
        margin-left: 5px !important;
    }

    .purchase_status {
        margin-left: 5px !important;
    }
}
</style>
