<template>
    <b-container>
        <b-row>
            <b-col>
                <p class="my-2 text-center">
                    <router-link class="text-uppercase fw-600 grey-color text-decoration-none fs-14" v-bind:to="{ name: 'notices.fl44', query: { status: 'accepting' } }">44-ФЗ Извещения </router-link>
                </p>
                <p class="my-2 text-center">
                    <router-link class="text-uppercase fw-600 grey-color text-decoration-none fs-14" :class="$route.matched[0]?.path === '/contracts/fl44' && 'router-link-active'" v-bind:to="{ name: 'ContractsList', params: { contract: 'all' } }">44-ФЗ Контракты </router-link>
                </p>
                <p class="my-4 text-center">
                    <router-link class="text-uppercase fw-600 grey-color text-decoration-none fs-14" :class="$route.matched[0]?.path === '/notices/fl223' && 'router-link-active'" v-bind:to="{ name: 'Fl223NoticesList', params: { status: 'accepting' } }">223-ФЗ Извещения </router-link>
                </p>
                <p class="my-4 text-center">
                    <router-link class="text-uppercase fw-600 grey-color text-decoration-none fs-14" :class="$route.matched[0]?.path === '/notices/commercial' && 'router-link-active'" v-bind:to="{ name: 'CommercialNoticesList', params: { status: 'accepting' } }">Коммерческие извещения </router-link>
                </p>
                <p class="my-4 text-center">
                    <router-link class="text-uppercase fw-600 grey-color text-decoration-none fs-14" :to="'/quotation-requests'">Запросы КП </router-link>
                </p>
                <p class="my-4 text-center">
                    <router-link class="text-uppercase fw-600 grey-color text-decoration-none fs-14" :to="{ name: 'ImportSubstitutionRequestsList', params: { path: 'open' } }">Импортозамещение</router-link>
                </p>
                <p class="my-4 text-center">
                    <router-link class="text-uppercase fw-600 grey-color text-decoration-none fs-14" :to="'/organizations'">ЗАКАЗЧИКИ И ПОСТАВЩИКИ </router-link>
                </p>
                <p class="my-4 text-center" v-if="$links.marketplace_link">
                    <a :href="$links.marketplace_link" target="_blank" class="atmo-link-off text-uppercase fw-600 grey-color text-decoration-none fs-14"> БАШМАРКЕТ </a>
                </p>
                <p class="my-4 text-center">
                    <a :href="$links.info_materials" target="_blank" class="atmo-link-off text-uppercase fw-600 grey-color text-decoration-none fs-14"> ИНФОРМАЦИОННЫЕ МАТЕРИАЛЫ </a>
                </p>
                <div class="d-flex flex-column align-items-center">
                    <router-link tag="button" exact class="text-uppercase text-nowrap btn btn-custom-green" v-if="!!$store.state.token" :to="{ name: 'Cabinet' }">Личный кабинет </router-link>
                    <b-btn class="text-nowrap text-uppercase" variant="custom-green" v-if="!$store.state.token" @click="goToLogin">Вход в ЛК</b-btn>
                    <b-btn class="text-nowrap text-uppercase mt-2" variant="custom-outline-secondary" v-if="!!$store.state.token" @click="logout">Выйти</b-btn>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import { __LINKS } from '@/const';

export default {
    mounted() {
        let _this = this;
        document.getElementById('full-menu').addEventListener('click', function (e) {
            if (e.target.tagName.toLowerCase() === 'a') {
                _this.$emit('hide-menu');
            }
        });
    },
    methods: {
        showModal() {
            this.$emit('show-modal');
        },
        goToLogin() {
            this.$emit('gotologin');
        },
        logout() {
            this.$emit('logout');
        }
    },
    computed: {
        links() {
            return __LINKS;
        }
    }
};
</script>

<style scoped>
.full_menu-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.list-item {
    margin: 5px 0;
}

.full_menu-list .list-item a {
    color: var(--grey-color);
    font-size: 14px;
    text-decoration: none;
}
.router-link-active {
    color: var(--green-color) !important;
}
</style>
