var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-group",
    {
      attrs: {
        "label-class": "fs-14 grey-color fw-700 d-flex align-items-baseline",
        la: "",
        "label-cols-lg": _vm.labelColsLg,
        "label-cols-sm": _vm.labelColsSm,
      },
      scopedSlots: _vm._u([
        {
          key: "label",
          fn: function () {
            return [
              _vm._v(" " + _vm._s(_vm.title) + ": "),
              !!_vm.hint
                ? _c("div", { staticClass: "atmo-help" }, [
                    _vm._v("  "),
                    _c("i", { staticClass: "atmo-icon-question-mark" }),
                    _c("span", { staticClass: "atmo-help__text" }, [
                      _vm._v(_vm._s(_vm.hint)),
                    ]),
                  ])
                : _vm._e(),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "div",
        { staticClass: "d-flex justify-content-between" },
        [
          !!_vm.document
            ? [
                _c(
                  "a",
                  {
                    staticClass: "fs-14 col-form-label my-auto",
                    attrs: {
                      href: _vm.$getAttachmentDownloadLink(_vm.document.id),
                      target: "_blank",
                    },
                  },
                  [_vm._v(_vm._s(_vm.document.name))]
                ),
                _c(
                  "span",
                  { staticClass: "float-right my-auto" },
                  [
                    _vm.withSend
                      ? _c(
                          "b-button",
                          {
                            staticClass: "my-auto mr-2 fixed-height",
                            attrs: {
                              variant: "custom-green-small",
                              disabled: _vm.disabled,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$emit("send")
                              },
                            },
                          },
                          [
                            _c("b-icon", {
                              staticClass: "my-auto mr-2",
                              attrs: { icon: "reply", "aria-hidden": "true" },
                            }),
                            _vm._v(_vm._s(_vm.sendTitle)),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("action-button-small", {
                      attrs: { icon: "trash", disabled: _vm.disabled },
                      on: {
                        click: function ($event) {
                          return _vm.removeFile(0, true)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            : [
                _c(
                  "p",
                  {
                    staticClass: "fs-14 col-form-label my-auto",
                    class: !_vm.state ? "text-danger" : "",
                  },
                  [_vm._v("Прикрепите документ")]
                ),
                _c(
                  "file-uploader",
                  {
                    staticClass: "float-right my-auto",
                    attrs: {
                      "is-disabled": !!_vm.document || _vm.disabled,
                      "is-main": true,
                    },
                    on: { set_file: _vm.addFile },
                  },
                  [_vm._v("Загрузить")]
                ),
              ],
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }