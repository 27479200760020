import store from '@/store';
import axios from 'axios';
import { BaseCommercialApiService } from './_base';

export class CommercialPurchases extends BaseCommercialApiService {
    constructor() {
        super('purchases/commercial');
    }

    async show(id) {
        try {
            const response = await axios.get(this.getUrl(id));
            return response.data.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async getDraft(id) {
        try {
            const response = await axios.get(this.getUrl(`${id}/edit`));
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async retrieveTemplate() {
        try {
            const response = await axios.get(this.getUrl('create'));
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async copy(id) {
        try {
            const response = await axios.post(this.getUrl(`${id}/copy`));
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async delete(id) {
        try {
            await axios.delete(this.getUrl(`${id}/delete`));
            await store.dispatch('showSuccessToast', 'Закупка успешно удалена');
            return true;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async getXml(id) {
        try {
            const response = await axios.get(this.getUrl(`${id}/xml`));
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async getProposalXML(purchaseId, form) {
        try {
            const response = await axios.post(this.getUrl(`${purchaseId}/proposals/get_xml`), form);
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async storeSignedProposal(id, proposalData, xml, certificate) {
        try {
            await axios.post(this.getUrl(`${id}/proposals/save`), {
                proposal: proposalData,
                xml: xml,
                certificate: certificate
            });
            await store.dispatch('showSuccessToast', 'Предложение успешно подано');
            return true;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async getProposalCreateData(id) {
        try {
            const response = await axios.get(this.getUrl(`${id}/proposals/retrieve`));
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async createDraft(payload) {
        try {
            const response = await axios.post(this.getUrl('create'), payload);
            await store.dispatch('showSuccessToast', 'Черновик извещения успешно сохранен');
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async updateDraft(id, payload) {
        try {
            const response = await axios.post(this.getUrl(`${id}/edit`), payload);
            await store.dispatch('showSuccessToast', 'Черновик извещения успешно сохранен');
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async updateComment(id, comment) {
        try {
            await axios.put(this.getUrl(`${id}/comment`), { comment });
            return true;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async publish(id) {
        try {
            await axios.post(this.getUrl(`${id}/publish`));
            await store.dispatch('showSuccessToast', 'Извещение успешно опубликовано');
            return true;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async unpublish(id) {
        try {
            await axios.post(this.getUrl(`${id}/unpublish`));
            await store.dispatch('showSuccessToast', 'Извещение успешно снято с публикации для редактирования');
            return true;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async cancel(id, data) {
        try {
            await axios.post(this.getUrl(id) + '/cancel', data);
            await store.dispatch('showSuccessToast', 'Закупка успешно отменена');
            return true;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async storeSigned(id, data, xml, certificate = null, signedAttachments) {
        if (!certificate) {
            certificate = store.getters.currentParsedCertificate;
        }
        try {
            await axios.post(this.getUrl(`${id}/save`), {
                ...data,
                xml: xml,
                certificate: certificate,
                signed_attachments: signedAttachments
            });
            await store.dispatch('showSuccessToast', 'Закупочная сессия сформирована');
            return true;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async storeWinnerSelection(id, data) {
        try {
            await axios.post(this.getUrl(id) + '/protocol/store', data);
            return true;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async setWinnerProposal(purchaseId, proposalId) {
        try {
            await axios.post(this.getUrl(purchaseId) + '/proposals/' + proposalId + '/accept');
            return true;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async rejectProposal(purchaseId, proposalId, data) {
        try {
            await axios.post(this.getUrl(purchaseId) + '/proposals/' + proposalId + '/reject', data);
            return true;
        } catch (err) {
            this.handleErrors(err);
        }
    }
}
