var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        visible: _vm.show,
        "hide-footer": "",
        "no-close-on-backdrop": "",
        scrollable: "",
        "no-close-on-esc": "",
        size: "xl",
        title: "Спецификация",
      },
      on: { cancel: _vm.cancelSpecModal, close: _vm.cancelSpecModal },
      scopedSlots: _vm._u([
        {
          key: "modal-header-close",
          fn: function () {
            return [_c("button-close-modal")]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "b-row",
        [
          _c("filter-text", {
            attrs: {
              md: 0,
              label: "Рег.номер",
              placeholder: "Введите рег.номер",
            },
            on: { input: _vm.onKtruFilterChange },
            model: {
              value: _vm.ktruTableFilter.reg_number,
              callback: function ($$v) {
                _vm.$set(_vm.ktruTableFilter, "reg_number", $$v)
              },
              expression: "ktruTableFilter.reg_number",
            },
          }),
          _c("filter-text", {
            attrs: {
              md: 0,
              label: "Название",
              placeholder: "Введите название",
            },
            on: { input: _vm.onKtruFilterChange },
            model: {
              value: _vm.ktruTableFilter.name,
              callback: function ($$v) {
                _vm.$set(_vm.ktruTableFilter, "name", $$v)
              },
              expression: "ktruTableFilter.name",
            },
          }),
          _c("filter-text", {
            attrs: {
              md: 0,
              label: "Характеристики",
              placeholder: "Введите текст характеристик",
            },
            on: { input: _vm.onKtruFilterChange },
            model: {
              value: _vm.ktruTableFilter.specs,
              callback: function ($$v) {
                _vm.$set(_vm.ktruTableFilter, "specs", $$v)
              },
              expression: "ktruTableFilter.specs",
            },
          }),
          _c("filter-text", {
            attrs: {
              md: 0,
              label: "Код ОКПД2",
              placeholder: "Введите код ОКПД2 или его часть",
            },
            on: { input: _vm.onKtruFilterChange },
            model: {
              value: _vm.ktruTableFilter.okpd_code,
              callback: function ($$v) {
                _vm.$set(_vm.ktruTableFilter, "okpd_code", $$v)
              },
              expression: "ktruTableFilter.okpd_code",
            },
          }),
          _vm.allowFavorite
            ? _c(
                "b-col",
                {
                  staticClass: "favorite-btn text-right",
                  attrs: { md: "auto" },
                },
                [
                  _vm.ktruTableFilter.showFavorite
                    ? _c(
                        "b-button",
                        {
                          staticClass: "text-uppercase",
                          attrs: { variant: "custom-outline-secondary" },
                          on: { click: _vm.showHideFavorites },
                        },
                        [
                          _vm._v("Избранное "),
                          _c("b-icon", {
                            staticClass: "ml-2",
                            attrs: { icon: "star-fill" },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "b-button",
                        {
                          staticClass: "text-uppercase",
                          attrs: { variant: "custom-green" },
                          on: { click: _vm.showHideFavorites },
                        },
                        [
                          _vm._v("Избранное "),
                          _c("b-icon", {
                            staticClass: "ml-2",
                            attrs: { icon: "star" },
                          }),
                        ],
                        1
                      ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-row",
        [
          !_vm.ktruItems.length
            ? _c("b-col", { staticClass: "text-center mt-5" }, [
                _c("img", {
                  attrs: { src: "/images/empty_box.svg", alt: "empty-data" },
                }),
                _c("p", { staticClass: "fs-14 text-danger my-4" }, [
                  _vm._v(" Позиции КТРУ по вашему запросу не найдены. "),
                  _vm.allowCreateRequest
                    ? _c(
                        "span",
                        [
                          _vm._v(" Вы можете "),
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: { name: "KtruRequestsCreate" },
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                "сформировать запрос на добавление позиции КТРУ"
                              ),
                            ]
                          ),
                          _vm._v("."),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ])
            : _c(
                "b-col",
                { staticClass: "mb-3" },
                [
                  _c("b-table", {
                    staticClass: "table-atmo",
                    attrs: {
                      id: "ktru-table",
                      busy: _vm.isLoading,
                      fields: _vm.fields,
                      items: _vm.ktruItems,
                      small: "",
                      hover: "",
                      responsive: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "row-details",
                        fn: function (data) {
                          return [
                            data.item.description
                              ? _c("p", [
                                  _c("b", [_vm._v("Описание:")]),
                                  _vm._v(" " + _vm._s(data.item.description)),
                                ])
                              : _vm._e(),
                            data.item.specs && data.item.specs.length > 0
                              ? _c("p", [_c("b", [_vm._v("Характеристики:")])])
                              : _vm._e(),
                            data.item.specs && data.item.specs.length > 0
                              ? _c(
                                  "ul",
                                  _vm._l(data.item.specs, function (spec, key) {
                                    return _c("li", { key: "spec" + key }, [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { "font-weight": "900" },
                                        },
                                        [_vm._v(_vm._s(spec.name))]
                                      ),
                                      _vm._v(": " + _vm._s(spec.value) + " "),
                                    ])
                                  }),
                                  0
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                      {
                        key: "cell(reg_num)",
                        fn: function (data) {
                          return [
                            _vm._v(" " + _vm._s(data.item.reg_number) + " "),
                          ]
                        },
                      },
                      {
                        key: "cell(okpd)",
                        fn: function (data) {
                          return [
                            _vm._v(" " + _vm._s(data.item.okpd_code) + " "),
                          ]
                        },
                      },
                      {
                        key: "cell(specs)",
                        fn: function (data) {
                          return [
                            _c("action-button-small", {
                              directives: [
                                {
                                  name: "b-popover",
                                  rawName: "v-b-popover.hover.top",
                                  value:
                                    (data.detailsShowing
                                      ? "Скрыть"
                                      : "Показать") + " характеристики",
                                  expression:
                                    "(data.detailsShowing ? 'Скрыть' : 'Показать') + ' характеристики'",
                                  modifiers: { hover: true, top: true },
                                },
                              ],
                              attrs: {
                                icon: data.detailsShowing ? "eye-slash" : "eye",
                              },
                              on: { click: data.toggleDetails },
                            }),
                            _vm._v(" " + _vm._s(data.item.name) + " "),
                          ]
                        },
                      },
                      {
                        key: "cell(okei)",
                        fn: function (data) {
                          return [
                            _vm._v(
                              " " + _vm._s(data.item.okei_local_symbol) + " "
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(country)",
                        fn: function (data) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(data.item.country_of_origin_name) +
                                " "
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(actions)",
                        fn: function (data) {
                          return [
                            _c("action-button-small", {
                              attrs: { icon: "plus" },
                              on: {
                                click: function ($event) {
                                  return _vm.selectKtruItem(data.item)
                                },
                              },
                            }),
                            _vm.allowFavorite
                              ? [
                                  !data.item.is_favorite
                                    ? _c("action-button-small", {
                                        directives: [
                                          {
                                            name: "b-popover",
                                            rawName: "v-b-popover.hover.left",
                                            value: "Добавить в избранное",
                                            expression:
                                              "'Добавить в избранное'",
                                            modifiers: {
                                              hover: true,
                                              left: true,
                                            },
                                          },
                                        ],
                                        staticClass: "ml-1",
                                        attrs: { icon: "star" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addDeleteFavoriteItem(
                                              data.item
                                            )
                                          },
                                        },
                                      })
                                    : _c("action-button-small", {
                                        directives: [
                                          {
                                            name: "b-popover",
                                            rawName: "v-b-popover.hover.left",
                                            value: "Удалить из избранного",
                                            expression:
                                              "'Удалить из избранного'",
                                            modifiers: {
                                              hover: true,
                                              left: true,
                                            },
                                          },
                                        ],
                                        staticClass: "ml-1",
                                        attrs: {
                                          variant: "outline",
                                          icon: "star-fill",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addDeleteFavoriteItem(
                                              data.item
                                            )
                                          },
                                        },
                                      }),
                                ]
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c(
                    "div",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "b-overlay",
                        {
                          staticClass: "d-inline-block",
                          attrs: {
                            show:
                              _vm.isLoading &&
                              _vm.ktruItems &&
                              _vm.meta.total > 0 &&
                              _vm.meta.last_page > 1,
                            opacity: "0.6",
                            rounded: "",
                            "spinner-small": "",
                            "spinner-variant": "primary",
                          },
                        },
                        [
                          _vm.ktruItems &&
                          _vm.meta.total > 0 &&
                          _vm.meta.last_page > 1
                            ? _c("b-pagination", {
                                staticClass: "my-0 py-1",
                                attrs: {
                                  "per-page": _vm.meta.per_page,
                                  "total-rows": _vm.meta.total,
                                  "aria-controls": "ktru-table",
                                },
                                on: { input: _vm.getKtruItems },
                                model: {
                                  value: _vm.meta.current_page,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.meta, "current_page", $$v)
                                  },
                                  expression: "meta.current_page",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }