var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    { staticClass: "fs-14 my-2" },
    [
      _c("proposal-card-title", {
        staticClass: "fs-16",
        attrs: { index: _vm.index, proposal: _vm.proposal },
      }),
      _c(
        "b-row",
        { staticClass: "mb-0 pb-0" },
        [
          _c(
            "b-col",
            { staticClass: "my-1", attrs: { md: "6" } },
            [
              _vm._v(" Время подачи предложения: "),
              _c("strong", [
                _vm._v(
                  _vm._s(_vm.getDateTimeFormatSeconds(_vm.proposal.created_at))
                ),
              ]),
              _c("br"),
              _c("br"),
              _c(
                "b",
                {
                  class:
                    "fs-16" +
                    (_vm.proposal.status.id === "winner"
                      ? " green-color"
                      : _vm.proposal.status.id === "rejected"
                      ? " text-danger"
                      : ""),
                },
                [_vm._v(_vm._s(_vm.proposal.status.title) + ". ")]
              ),
              _vm.proposal.status.id === "rejected"
                ? [
                    _c("span", { staticClass: "fs-16" }, [
                      _c("b", [_vm._v("Причина отклонения:")]),
                      _vm._v(" " + _vm._s(_vm.proposal.rejection_reason)),
                    ]),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c(
            "b-col",
            { staticClass: "my-1", attrs: { md: "3" } },
            [
              _vm.proposal.supplier.rating !== null
                ? [
                    _vm._v(" Рейтинг ИС: "),
                    _c("strong", [
                      _vm._v(_vm._s(_vm.proposal.supplier.rating)),
                    ]),
                    _c("br"),
                  ]
                : _vm._e(),
              _vm._v(" Рейтинг Контур.Фокус: "),
              _vm.proposal.supplier.kontur_rating.red
                ? _c("shield-red")
                : _vm._e(),
              _vm.proposal.supplier.kontur_rating.yellow
                ? _c("shield-yellow")
                : _vm._e(),
              _vm.proposal.supplier.kontur_rating.green
                ? _c("shield-green")
                : _vm._e(),
            ],
            2
          ),
          _c(
            "b-col",
            { staticClass: "my-1 text-right", attrs: { md: "3" } },
            [
              _vm.purchase.status.id === "summarizing"
                ? _c(
                    "b-button",
                    {
                      staticClass: "fs-14 my-1 w-100",
                      attrs: {
                        variant: "custom-outline-secondary",
                        size: "sm",
                        href: _vm.rnpCheckLink,
                        target: "_blank",
                      },
                    },
                    [_vm._v("Проверить в РНП")]
                  )
                : _vm._e(),
              _c(
                "b-button",
                {
                  staticClass: "fs-14 my-1 w-100",
                  class: _vm.detailsVisible ? null : "collapsed",
                  attrs: {
                    "aria-expanded": _vm.detailsVisible ? "true" : "false",
                    "aria-controls": "collapse-proposal-details",
                    variant: "custom-grey",
                    size: "sm",
                  },
                  on: {
                    click: function ($event) {
                      _vm.detailsVisible = !_vm.detailsVisible
                    },
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.detailsVisible ? "Скрыть" : "Показать") +
                      " подробности "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-collapse",
        {
          attrs: { id: "collapse-proposal-details" },
          model: {
            value: _vm.detailsVisible,
            callback: function ($$v) {
              _vm.detailsVisible = $$v
            },
            expression: "detailsVisible",
          },
        },
        [
          _c(
            "b-row",
            { staticClass: "my-1" },
            [
              _c("b-col", { attrs: { md: "9" } }, [
                _c(
                  "div",
                  [
                    _vm._v(" Карточка предприятия - "),
                    _vm.proposal.organization_card &&
                    _vm.proposal.organization_card.id
                      ? [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.$getAttachmentDownloadLink(
                                  _vm.proposal.organization_card.id
                                ),
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.proposal.organization_card.name)
                              ),
                            ]
                          ),
                        ]
                      : [_vm._v(" Отсутствует")],
                  ],
                  2
                ),
                _vm.supplierRequirements.only_smp ||
                _vm.supplierRequirements.only_not_rnp ||
                _vm.supplierRequirements.has_additional_requirements
                  ? _c("div", [
                      _c("strong", [_vm._v("Требования к поставщикам:")]),
                      _c("br"),
                      _c(
                        "ul",
                        [
                          _vm.supplierRequirements.only_smp
                            ? [
                                _c(
                                  "li",
                                  [
                                    _vm.proposal.supplier_requirements_confirmed
                                      .is_smp
                                      ? [
                                          _c("shield-green"),
                                          _vm._v(
                                            " Поставщик является субъектом малого предпринимательства (СМП) "
                                          ),
                                        ]
                                      : [
                                          _c("shield-red"),
                                          _vm._v(" Поставщик "),
                                          _c("strong", [_vm._v("НЕ")]),
                                          _vm._v(
                                            " является субъектом малого предпринимательства (СМП) "
                                          ),
                                        ],
                                  ],
                                  2
                                ),
                              ]
                            : _vm._e(),
                          _vm.supplierRequirements.only_not_rnp
                            ? [
                                _c(
                                  "li",
                                  [
                                    _vm.proposal.supplier_requirements_confirmed
                                      .is_not_rnp
                                      ? [
                                          _c("shield-green"),
                                          _vm._v(" Поставщик подтвердил, что "),
                                          _c("strong", [_vm._v("НЕ")]),
                                          _vm._v(
                                            " присутствует в реестре недобросовестных поставщиков "
                                          ),
                                        ]
                                      : [
                                          _c("shield-red"),
                                          _vm._v(" Поставщик "),
                                          _c("strong", [_vm._v("НЕ")]),
                                          _vm._v(
                                            " подтвердил, что не присутствует в реестре недобросовестных поставщиков "
                                          ),
                                        ],
                                  ],
                                  2
                                ),
                              ]
                            : _vm._e(),
                          _vm.supplierRequirements.has_additional_requirements
                            ? [
                                _c(
                                  "li",
                                  [
                                    _vm.proposal.supplier_requirements_confirmed
                                      .additional_requirements &&
                                    _vm.proposal.supplier_requirements_confirmed
                                      .additional_requirements.length > 0
                                      ? [
                                          _c("shield-green"),
                                          _vm._v(
                                            " Поставщик подтвердил что соответствует дополнительным требованиям "
                                          ),
                                        ]
                                      : [
                                          _c("shield-red"),
                                          _vm._v(" Поставщик "),
                                          _c("strong", [_vm._v("НЕ")]),
                                          _vm._v(
                                            " подтвердил что соответствует дополнительным требованиям "
                                          ),
                                        ],
                                  ],
                                  2
                                ),
                                _c("span", [
                                  _c("strong", [
                                    _vm._v(
                                      "Документы, подтверждающие соответствие требованиям:"
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "ul",
                                  _vm._l(
                                    _vm.proposal.supplier_requirements_confirmed
                                      .additional_requirements,
                                    function (additionalRequirement) {
                                      return _c(
                                        "li",
                                        { key: additionalRequirement.id },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                additionalRequirement.description
                                              ) +
                                              " (Причина или цель установки требования - " +
                                              _vm._s(
                                                additionalRequirement.reason
                                              ) +
                                              ") - "
                                          ),
                                          _c(
                                            "a",
                                            {
                                              staticClass: "fs-14",
                                              attrs: {
                                                href: _vm.$getAttachmentDownloadLink(
                                                  additionalRequirement
                                                    .confirmation_document.id
                                                ),
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  additionalRequirement
                                                    .confirmation_document.name
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ])
                  : _vm._e(),
                _c("div", [
                  _c("strong", [
                    _vm._v(
                      "Дополнительные документы, приложенные поставщиком к предложению:"
                    ),
                  ]),
                  _c("br"),
                  _vm.proposal.attachments.length > 0
                    ? _c(
                        "ul",
                        _vm._l(
                          _vm.proposal.attachments,
                          function (document, docIndex) {
                            return _c(
                              "li",
                              {
                                key:
                                  "provider_" +
                                  _vm.index +
                                  "_document_" +
                                  docIndex,
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: _vm.$getAttachmentDownloadLink(
                                        document.id
                                      ),
                                    },
                                  },
                                  [_vm._v(_vm._s(document.name))]
                                ),
                              ]
                            )
                          }
                        ),
                        0
                      )
                    : _c("span", [_vm._v("Отсутствуют")]),
                ]),
              ]),
              !_vm.confirmed
                ? _c(
                    "b-col",
                    { staticClass: "my-1", attrs: { md: "3" } },
                    [
                      _c("download-organization-analytics-report-button", {
                        attrs: { organization: _vm.proposal.supplier },
                      }),
                      _vm.withRating &&
                      (_vm.proposal.status.id === "actual" ||
                        _vm.proposal.status.id === "winner")
                        ? _c("form-row-edit-text-compact", {
                            staticClass: "my-1 fs-14 w-100 px-0 py-1",
                            attrs: {
                              "label-cols-lg": 12,
                              label: "Рейтинг:",
                              type: "number",
                            },
                            model: {
                              value: _vm.rating,
                              callback: function ($$v) {
                                _vm.rating = $$v
                              },
                              expression: "rating",
                            },
                          })
                        : _vm._e(),
                      _vm.proposal.status.id === "actual" &&
                      !_vm.proposalSelected
                        ? _c(
                            "b-button",
                            {
                              staticClass: "fs-14 my-1 w-100",
                              attrs: { size: "sm", variant: "custom-green" },
                              on: { click: _vm.confirmWinner },
                            },
                            [_vm._v(" Выбрать победителем")]
                          )
                        : _vm._e(),
                      _c("reject-fl223-proposal-button", {
                        attrs: {
                          proposal: _vm.proposal,
                          "purchase-id": _vm.purchaseId,
                        },
                        on: { refresh: _vm.refresh },
                      }),
                    ],
                    1
                  )
                : _c(
                    "b-col",
                    { staticClass: "my-1 text-right", attrs: { md: "3" } },
                    [
                      _c("p", { staticClass: "fs-14" }, [
                        _vm._v(
                          "Рейтинг: " +
                            _vm._s(
                              _vm.proposal.rating
                                ? _vm.proposal.rating
                                : "Не установлен"
                            )
                        ),
                      ]),
                    ]
                  ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "my-1" },
            [
              _c("b-col", { attrs: { md: "12" } }, [
                _c("strong", [_vm._v("Спецификация:")]),
                _c("br"),
                _c("table", { staticClass: "table" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", { staticClass: "tcw-50" }, [_vm._v("№")]),
                      _c("th", [_vm._v("Наименование товара (работ, услуг)")]),
                      _c("th", { staticClass: "tcw-100 text-right" }, [
                        _vm._v("Количество /"),
                        _c("br"),
                        _vm._v("Ед.изм"),
                      ]),
                      _c(
                        "th",
                        {
                          staticClass: "tcw-100 text-right",
                          attrs: { width: "120" },
                        },
                        [_vm._v("НДС, % /"), _c("br"), _vm._v("НДС, руб.")]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "tcw-100 text-right",
                          attrs: { width: "120" },
                        },
                        [_vm._v("Цена за ед. с НДС, руб. / без НДС, руб.")]
                      ),
                      _c(
                        "th",
                        {
                          staticClass: "tcw-100 text-right",
                          attrs: { width: "120" },
                        },
                        [_vm._v("Стоимость с НДС, руб. / без НДС, руб.")]
                      ),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    _vm._l(_vm.proposal.deliverables, function (item, index) {
                      return _c(
                        "tr",
                        _vm._b({ key: item.id }, "tr", item, false),
                        [
                          _c("td", [_vm._v(_vm._s(index + 1))]),
                          _c("td", [_vm._v(_vm._s(item.ktru_item.name))]),
                          _c("td", { staticClass: "text-right text-nowrap" }, [
                            _vm._v(" " + _vm._s(item.amount)),
                            _c("br"),
                            _vm._v(
                              " " +
                                _vm._s(item.ktru_item.okei_local_symbol) +
                                " "
                            ),
                          ]),
                          _c("td", { staticClass: "text-right text-nowrap" }, [
                            _vm._v(
                              " " + _vm._s(item.vat ? item.vat.title : "—")
                            ),
                            _c("br"),
                            _vm._v(
                              " " +
                                _vm._s(_vm.$formatPriceOrMdash(item.sum_vat)) +
                                " "
                            ),
                          ]),
                          _c("td", { staticClass: "text-right text-nowrap" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$formatPrice(item.price_per_unit_with_vat)
                                )
                            ),
                            _c("br"),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$formatPriceOrMdash(
                                    item.price_per_unit_wo_vat
                                  )
                                ) +
                                " "
                            ),
                          ]),
                          _c("td", { staticClass: "text-right text-nowrap" }, [
                            _vm._v(
                              " " + _vm._s(_vm.$formatPrice(item.sum_with_vat))
                            ),
                            _c("br"),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$formatPriceOrMdash(item.sum_wo_vat)
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }