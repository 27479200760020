<template>
    <div>
        <b-row>
            <b-col md="7" class="d-flex justify-content-between">
                <h3 class="grey-color mb-3 main_text font-weight-bold">Договоры 223-ФЗ</h3>
            </b-col>
        </b-row>

        <b-row class="mb-2 cabinet_filter">
            <!--<filter-new-multiple-select v-model="filter.status" :options="filterPurchaseStatuses" label-field="title" label="Статус закупки" label-class="grey-color" lg="6" md="6" placeholder="Выберите статус" /> TODO: Пока не имеет смысла -->
            <filter-new-text v-model="filter.regNumber" label="Рег.номер закупки" md="6" placeholder="Введите рег.номер закупки" />
            <filter-new-multiple-select v-model="filter.deliverableGroups" :options="$globalDictionaries.deliverableGroups" label="Предмет закупки" label-class="grey-color" lg="6" md="6" placeholder="Выберите предметы закупки" />
            <filter-new-text v-if="orgRoleIsPermitted($orgRoles.CUSTOMER_FL223)" v-model="filter.supplier" label="Поставщик" md="6" placeholder="Введите название организации или ИНН" />
            <filter-new-text v-if="orgRoleIsPermitted($orgRoles.SUPPLIER_FL223)" v-model="filter.customer" label="Заказчик" md="6" placeholder="Введите название организации или ИНН" />

            <b-col class="my-2 text-right" lg="12" md="12">
                <b-btn class="text-uppercase ml-2" style="height: 32px; line-height: 15px" variant="custom-green" @click.prevent="getContracts(true)">НАЙТИ</b-btn>
                <b-btn class="text-uppercase ml-2" style="height: 32px; line-height: 15px" variant="custom-outline-secondary" @click.prevent="cleanFilters">СБРОС</b-btn>
                <cabinet-fl223-contracts-export-button class="ml-2 d-inline-block" />
            </b-col>
        </b-row>

        <b-overlay :show="isLoading" rounded opacity="0.6" spinner-variant="primary">
            <b-pagination v-if="contracts && meta.total > 0 && meta.last_page > 1" v-model="meta.current_page" :per-page="meta.per_page" :total-rows="meta.total" align="center" class="my-1 py-0" />

            <empty-request v-if="isLoading || !contracts.length" />
            <template v-else v-for="contract in contracts">
                <cabinet-fl223-contract-card class="pb-2" :contract="contract" :key="`${contract.id}`" @refresh="getContracts" />
            </template>

            <b-pagination v-if="contracts && meta.total > 0 && meta.last_page > 1" v-model="meta.current_page" :per-page="meta.per_page" :total-rows="meta.total" align="center" class="my-1 py-0" @input="getContracts()" />
        </b-overlay>
    </div>
</template>

<script>
import FilterNewMultipleSelect from '@/components/common/filter-components/filter-new-multiple-select.vue';
import FilterNewText from '@/components/common/filter-components/filter-new-text.vue';
import CabinetFl223ContractsExportButton from '@/components/cabinets/fl223/cards/partials/cabinet-fl223-contracts-export-button.vue';
import CabinetFl223ContractCard from '@/components/cabinets/fl223/cards/cabinet-fl223-contract-card.vue';

const emptyFilter = {
    status: [],
    deliverableGroups: [],
    regNumber: '',
    customer: '',
    supplier: ''
};
export default {
    name: 'cabinet-fl223-contracts-list',
    components: {
        CabinetFl223ContractCard,
        CabinetFl223ContractsExportButton,
        FilterNewText,
        FilterNewMultipleSelect
    },
    data() {
        return {
            contracts: [],
            isLoading: false,
            number: '',
            id: null,
            showDeleteModal: false,
            showPublishModal: false,
            showCancelModal: false,
            filter: {
                ...emptyFilter
            },
            meta: {
                current_page: 1,
                total: 0,
                per_page: 10
            }
        };
    },
    async mounted() {
        await this.getContracts();
        this.$scrollToTop();
    },
    watch: {
        $route: 'getContracts'
    },
    methods: {
        getFilters(page = true) {
            const filterValues = [];
            for (const [key, value] of Object.entries(this.filter)) {
                if (value) {
                    filterValues[key] = value;
                }
            }
            return {
                ...filterValues,
                page: page ? this.meta.current_page : 1
            };
        },
        async getContracts(page = true) {
            console.log(this.filterPurchaseStatuses);
            this.isLoading = true;
            const contractsData = await this.$api.personal.fl223.getContractsList(this.getFilters(page));
            this.contracts = contractsData.data;
            this.meta = contractsData.meta;
            this.isLoading = false;
        },
        async cleanFilters() {
            this.filter = { ...emptyFilter };
            await this.getContracts();
        }
    },
    computed: {
        filterPurchaseStatuses() {
            const filterValues = ['contract:active'];
            return this.$globalDictionaries.purchaseStatuses.filter((status) => filterValues.some((value) => status.id === value));
        }
    }
};
</script>
<style scoped>
.nav-link,
.nav-item,
.nav-menu {
    padding: 0 !important;
    margin-bottom: -0.04rem !important;
}

.nav-item .nav-link {
    color: #666;
}

.nav-item .nav-link:hover {
    color: #444;
}

.nav-menu {
    font-family: unset;
    font-weight: unset;
    font-size: 14px;
    color: #666666;
}

.nav-menu :hover {
    color: #555555;
}

.nav-link.disabled {
    color: #26b89a !important;
    border-bottom: 2px solid #26b89a;
    font-weight: 600;
}

input::-webkit-input-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input:-moz-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input::-moz-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input:-ms-input-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input::-ms-input-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input::placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input {
    border-color: rgba(149, 155, 164, 0.2);
}
</style>
