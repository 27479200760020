<template>
    <div>
        <template v-if="$store.getters.isLoggedIn && purchase.actions.customer.select_winner && purchase.private_data && purchase.private_data.winner_selection && purchase.status.id === 'summarizing'">
            <div id="atmo-winner-select" class="mx-1 mb-2">
                <b-btn variant="danger" class="fs-14 my-2 text-uppercase" style="color: white !important" size="lg" :href="$links.commercial_purchase_winner_selection_manual" target="_blank">
                    <i class="atmo-icon-play" />
                    Инструкция по формированию итогового протокола</b-btn
                >
            </div>

            <template v-if="purchase.private_data.proposals && purchase.private_data.proposals.length > 0">
                <b-row>
                    <b-col md="10">
                        <h2 class="grey-color font-weight-bold my-3">Предложения участников</h2>
                    </b-col>
                </b-row>
                <private-commercial-winner-selection-proposal-card
                    v-for="(proposal, index) in purchase.private_data.proposals"
                    :key="'proposal_' + index"
                    :proposal="proposal"
                    :purchase-id="purchase.id"
                    :supplier-requirements="purchase.supplier_requirements"
                    :purchase="purchase"
                    :index="index"
                    :with-rating="true"
                    v-model="proposal.rating"
                    :confirmed="purchase.private_data.winner_selection.confirmed"
                    :proposal-selected="winnerProposalsSelected"
                    @refresh="refresh" />
                <commercial-purchase-store-winner-selection-button :purchase="purchase" :ratings="proposalRatings" :proposals="purchase.private_data.proposals" @store="refresh" />
            </template>
        </template>

        <template v-if="$store.getters.isLoggedIn && purchase.actions.customer.select_winner && purchase.private_data && purchase.private_data.winner_selection && purchase.private_data.winner_selection.confirmed && purchase.status.id === 'summarizing'">
            <text-header>Итоговый протокол закупочной сессии</text-header>
            <a class="btn btn-custom-green text-uppercase" :href="purchase.private_data.winner_selection.protocol_template_link">Скачать итоговый протокол закупочной сессии</a>
        </template>
    </div>
</template>

<script>
import PrivateCommercialWinnerSelectionProposalCard from '@/components/products/commercial/partials/private-commercial-winner-selection-proposal-card.vue';
import CommercialPurchaseStoreWinnerSelectionButton from '@/components/products/commercial/partials/commercial-purchase-store-winner-selection-button.vue';
import { required } from 'vuelidate/lib/validators';

export default {
    name: 'private-commercial-winner-selection-block',
    components: { CommercialPurchaseStoreWinnerSelectionButton, PrivateCommercialWinnerSelectionProposalCard },
    props: ['purchase'],
    data() {
        return {
            showConfirmModal: false,
            showStoreModal: false,
            form: {
                attachment: null
            },
            ratings: [],
            isDataSending: false
        };
    },
    validations() {
        return {
            form: {
                attachment: {
                    required
                }
            }
        };
    },
    methods: {
        async confirmWinnerSelection() {
            this.isDataSending = true;
            if (await this.$api.products.commercial.purchases.confirmWinnerSelection(this.purchase.id, this.form)) {
                this.showConfirmModal = false;
                this.$emit('confirm');
            }
            this.isDataSending = false;
        },
        refresh() {
            this.$emit('refresh');
        }
    },
    computed: {
        winnerProposalsSelected() {
            return this.purchase.private_data.proposals.filter((proposal) => proposal.status.id === 'winner').length > 0;
        },
        proposalRatings() {
            return this.purchase.private_data && this.purchase.private_data.proposals
                ? this.purchase.private_data.proposals.map(function (proposal) {
                      return {
                          id: proposal.id,
                          rating: parseInt(proposal.rating) ?? null
                      };
                  })
                : null;
        }
    }
};
</script>
