var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-nav",
    {
      staticClass: "w-100 provider_menu cabinet_menu mb-3",
      attrs: { vertical: "" },
    },
    [
      _vm.isUserProvider ||
      _vm.isUserCustomer ||
      _vm.orgRoleIsPermitted(_vm.$orgRoles.CUSTOMER_FL44) ||
      _vm.orgRoleIsPermitted(_vm.$orgRoles.SUPPLIER_FL44)
        ? _c(
            "cabinet-submenu",
            { attrs: { path: "/cabinet/fl44", title: "44-ФЗ", strong: "" } },
            [
              _vm.isUserCustomer ||
              _vm.orgRoleIsPermitted(_vm.$orgRoles.CUSTOMER_FL44)
                ? _c(
                    "cabinet-submenu-item",
                    {
                      attrs: {
                        to: {
                          name: "CustomerPurchasesCabinet",
                          params: { cat: "all" },
                        },
                      },
                    },
                    [_vm._v("Извещения")]
                  )
                : _vm._e(),
              _vm.isUserCustomer ||
              _vm.orgRoleIsPermitted(_vm.$orgRoles.CUSTOMER_FL44)
                ? _c(
                    "cabinet-submenu-item",
                    {
                      attrs: {
                        to: {
                          name: "CustomerContractsCabinet",
                          params: { cat: "all" },
                        },
                      },
                    },
                    [_vm._v("Контракты")]
                  )
                : _vm._e(),
              _vm.isUserProvider ||
              _vm.orgRoleIsPermitted(_vm.$orgRoles.SUPPLIER_FL44)
                ? _c(
                    "cabinet-submenu-item",
                    {
                      attrs: {
                        to: {
                          name: "ProviderContractsCabinet",
                          params: { cat: "all" },
                        },
                      },
                    },
                    [_vm._v("Контракты")]
                  )
                : _vm._e(),
              _vm.orgRoleIsPermitted(_vm.$orgRoles.SUPPLIER_FL44)
                ? _c(
                    "cabinet-submenu-item",
                    { attrs: { to: { name: "CabinetFl44ProposalsList" } } },
                    [_vm._v(" Предложения ")]
                  )
                : _vm._e(),
              _vm.orgRoleIsPermitted(_vm.$orgRoles.CUSTOMER_FL44)
                ? _c(
                    "cabinet-submenu-item",
                    { attrs: { to: { name: "CabinetFl44PurchaseReports" } } },
                    [_vm._v("Отчеты по закупкам")]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.orgRoleIsPermitted(_vm.$orgRoles.CUSTOMER_FL223) ||
      _vm.orgRoleIsPermitted(_vm.$orgRoles.SUPPLIER_FL223)
        ? _c(
            "cabinet-submenu",
            { attrs: { path: "/cabinet/fl223", title: "223-ФЗ", strong: "" } },
            [
              _vm.orgRoleIsPermitted(_vm.$orgRoles.CUSTOMER_FL223)
                ? _c(
                    "cabinet-submenu-item",
                    { attrs: { to: { name: "CabinetFl223Notices" } } },
                    [_vm._v("Извещения")]
                  )
                : _vm._e(),
              _c(
                "cabinet-submenu-item",
                { attrs: { to: { name: "CabinetFl223Contracts" } } },
                [_vm._v("Договоры")]
              ),
              _vm.orgRoleIsPermitted(_vm.$orgRoles.SUPPLIER_FL223)
                ? _c(
                    "cabinet-submenu-item",
                    { attrs: { to: { name: "CabinetFl223ProposalsList" } } },
                    [_vm._v("Предложения")]
                  )
                : _vm._e(),
              _vm.orgRoleIsPermitted(_vm.$orgRoles.CUSTOMER_FL223)
                ? _c(
                    "cabinet-submenu-item",
                    { attrs: { to: { name: "CabinetFl223Settings" } } },
                    [_vm._v("Настройки")]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.orgRoleIsPermitted(_vm.$orgRoles.CUSTOMER_COMMERCIAL) ||
      _vm.orgRoleIsPermitted(_vm.$orgRoles.SUPPLIER_COMMERCIAL)
        ? _c(
            "cabinet-submenu",
            {
              attrs: {
                path: "/cabinet/commercial",
                title: "Коммерческие",
                strong: "",
              },
            },
            [
              _vm.orgRoleIsPermitted(_vm.$orgRoles.CUSTOMER_COMMERCIAL)
                ? _c(
                    "cabinet-submenu-item",
                    { attrs: { to: { name: "CabinetCommercialNotices" } } },
                    [_vm._v(" Извещения ")]
                  )
                : _vm._e(),
              _c(
                "cabinet-submenu-item",
                { attrs: { to: { name: "CabinetCommercialContracts" } } },
                [_vm._v("Договоры")]
              ),
              _vm.orgRoleIsPermitted(_vm.$orgRoles.SUPPLIER_COMMERCIAL)
                ? _c(
                    "cabinet-submenu-item",
                    {
                      attrs: { to: { name: "CabinetCommercialProposalsList" } },
                    },
                    [_vm._v(" Предложения ")]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "cabinet-submenu",
        { attrs: { path: "/cabinet/quotation_requests", title: "Запросы КП" } },
        [
          _c(
            "cabinet-submenu-item",
            {
              attrs: {
                to: {
                  name: "QuotationRequestsRequestsList",
                  params: { cat: "all" },
                },
              },
            },
            [_vm._v("Запросы")]
          ),
          _c(
            "cabinet-submenu-item",
            {
              attrs: {
                to: {
                  name: "QuotationRequestsOffersList",
                  params: { cat: "all" },
                },
              },
            },
            [_vm._v("Предложения")]
          ),
        ],
        1
      ),
      _vm.isProductionsAllowed && _vm.isUserProvider
        ? _c(
            "cabinet-menu-item",
            { attrs: { to: { name: "ProviderProductionsList" } } },
            [_vm._v("Мое производство")]
          )
        : _vm._e(),
      _vm.isUserProvider
        ? _c(
            "cabinet-menu-item",
            { attrs: { to: { name: "ProviderAccountCabinet" } } },
            [_vm._v("Лицевой счет")]
          )
        : _vm._e(),
      _c(
        "cabinet-submenu",
        { attrs: { path: "/cabinet/ktru", title: "КТРУ" } },
        [
          _c(
            "cabinet-submenu-item",
            { attrs: { to: { name: "KtruRequestsList" } } },
            [_vm._v("Запросы на добавление позиции")]
          ),
          _c(
            "cabinet-submenu-item",
            { attrs: { to: { name: "KtruFavoritesList" } } },
            [
              _vm._v("Избранное"),
              _c(
                "span",
                {
                  staticClass: "atmo-badge",
                  staticStyle: { left: "94px", top: "5px" },
                },
                [_vm._v("Новое")]
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "cabinet-menu-item",
        { attrs: { to: { name: "NotificationsList" } } },
        [_vm._v("Системные уведомления")]
      ),
      _c(
        "cabinet-menu-item",
        { attrs: { to: { name: "PersonalAddressesCabinet" } } },
        [_vm._v("Адресная книга")]
      ),
      _vm.isUserProvider
        ? _c(
            "cabinet-menu-item",
            { attrs: { to: { name: "OrganizationReliabilityReport" } } },
            [_vm._v("Проверка контрагентов")]
          )
        : _vm._e(),
      _c(
        "cabinet-submenu",
        {
          attrs: {
            path: ["/cabinet/organization", "/cabinet/user_profile"],
            title: "Об организации",
          },
        },
        [
          _c(
            "cabinet-submenu-item",
            { attrs: { to: { name: "OrganizationData" } } },
            [_vm._v("Информация об организации")]
          ),
          _c(
            "cabinet-submenu-item",
            { attrs: { to: { name: "UserProfile" } } },
            [_vm._v("Данные учетной записи")]
          ),
          _c("cabinet-submenu-item", { attrs: { to: { name: "UsersList" } } }, [
            _vm._v("Пользователи организации"),
          ]),
          _vm.orgRoleIsPermitted(_vm.$orgRoles.CUSTOMER_FL44)
            ? _c(
                "cabinet-submenu-item",
                { attrs: { to: { name: "CabinetFl44Limits" } } },
                [_vm._v("Лимиты БО")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }