<template>
    <div>
        <h3 class="grey-color mb-3 main_text font-weight-bold">Извещения 223-ФЗ</h3>

        <b-row class="mb-2 cabinet_filter">
            <filter-new-text v-model="filter.reg_number" label="Рег.номер закупки" md="6" placeholder="Введите рег.номер закупки" />
            <filter-new-multiple-select v-model="filter.status" :options="filterStatuses" label="Статус закупки" label-class="grey-color" label-field="title" lg="6" md="6" placeholder="Выберите статус" />
            <filter-new-multiple-select v-model="filter.deliverable_groups" :options="$globalDictionaries.deliverableGroups" label="Предмет закупки" label-class="grey-color" lg="6" md="6" placeholder="Выберите предметы закупки" />

            <b-col class="mb-1 mt-auto" lg="3" md="3">
                <b-btn class="text-uppercase w-100" style="height: 32px; line-height: 15px" variant="custom-green" @click.prevent="getPurchases(true)">НАЙТИ</b-btn>
            </b-col>
            <b-col class="mb-1 mt-auto" lg="3" md="3">
                <b-btn class="text-uppercase w-100" style="height: 32px; line-height: 15px" variant="custom-outline-secondary" @click.prevent="clearFilter">СБРОС</b-btn>
            </b-col>
        </b-row>

        <b-pagination v-if="purchases && meta.total > 0 && meta.last_page > 1" v-model="meta.current_page" :per-page="meta.per_page" :total-rows="meta.total" align="center" class="my-1" />

        <div v-for="purchase in purchases" :key="purchase.id">
            <cabinet-fl223-notice-card :purchase="purchase" @refresh="getPurchases()" />
        </div>
        <empty-request v-if="!purchases.length && !loading"></empty-request>

        <b-pagination v-if="purchases && meta.total > 0 && meta.last_page > 1" v-model="meta.current_page" :per-page="meta.per_page" :total-rows="meta.total" align="center" class="my-1" @input="getPurchases()" />
    </div>
</template>

<script>
import CabinetFl223NoticeCard from '@/components/cabinets/fl223/cards/cabinet-fl223-notice-card.vue';
import FilterNewMultipleSelect from '@/components/common/filter-components/filter-new-multiple-select.vue';
import FilterNewText from '@/components/common/filter-components/filter-new-text.vue';

export default {
    name: 'cabinet-fl223-notices-list',
    metaInfo: {
        title: 'Закупки 223ФЗ. Извещения'
    },
    components: {
        FilterNewText,
        FilterNewMultipleSelect,
        CabinetFl223NoticeCard
    },
    data() {
        return {
            purchases: [],
            loading: false,
            filter: {
                status: [],
                deliverable_groups: [],
                reg_number: ''
            },
            meta: {
                current_page: 1,
                total: 0,
                per_page: 1
            }
        };
    },
    async mounted() {
        await this.getPurchases();
    },
    methods: {
        getFilters(page = true) {
            const filterValues = [];
            for (let obj of Object.entries(this.filter)) {
                if (obj[1]) {
                    filterValues[obj[0]] = obj[1];
                }
            }
            return {
                ...filterValues,
                page: page ? this.meta.current_page : 1
            };
        },
        clearFilter() {
            this.filter.deliverable_groups = [];
            this.filter.status = [];
            this.filter.reg_number = '';
            this.getPurchases(false);
        },

        async getPurchases(page = true, loading = true) {
            this.loading = loading;
            const purchasesData = await this.$api.personal.fl223.getNoticesList(this.getFilters(page));
            this.purchases = purchasesData.data;
            this.meta = purchasesData.meta;
            this.$scrollToTop();
            this.loading = false;
        },
        refresh() {
            this.getPurchases();
        }
    },
    computed: {
        filterStatuses() {
            const filterValues = ['draft', 'signed', 'revoked', 'accepting', 'summarizing', 'summarized', 'cancelled', 'failed'];
            return this.$globalDictionaries.purchaseStatuses.filter((status) => filterValues.some((value) => status.id === value));
        }
    }
};
</script>
