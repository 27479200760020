import { Fl223OrganizationInformation } from '@/services/backend/products/fl223/organization-information';
import { Fl223Purchases } from '@/services/backend/products/fl223/purchases';
import { Fl223Contracts } from '@/services/backend/products/fl223/contracts';
import { Fl223Notices } from '@/services/backend/products/fl223/notices';

export default {
    organizationInfo: new Fl223OrganizationInformation(),
    purchases: new Fl223Purchases(),
    contracts: new Fl223Contracts(),
    notices: new Fl223Notices()
};
